import fetchIntercept from "fetch-intercept"
import { getToken } from "./getToken"
import { getTokenDM } from "./getTokenDM"

const { REACT_APP_NODE_AUTH, REACT_APP_NODE_DM_AUTH } = process.env
function deleteAllCookies() {
  const cookies = document.cookie.split(";")

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i]
    const eqPos = cookie.indexOf("=")
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT"
  }
}
export const unregister = fetchIntercept.register({
  request: async function (url, config) {
    if (url.includes(REACT_APP_NODE_AUTH)) {
      const accessToken = await getToken(config.method, `/moat/searchResult`)
      const modifiedHeader = new Headers(config.headers)
      if (
        modifiedHeader.keys().next().value != "custom-type" &&
        modifiedHeader.values().next().value != "upload"
      ) {
        modifiedHeader.append("Content-Type", "application/json")
      }
      const pop = `PoP ${accessToken}`
      modifiedHeader.delete("Authorization")
      modifiedHeader.append("Authorization", pop)
      config.headers = modifiedHeader
    }
    if (url.includes(REACT_APP_NODE_DM_AUTH)) {
      const accessToken = await getTokenDM(config.method, `/moat/searchResult`)
      const modifiedHeader = new Headers(config.headers)
      if (
        modifiedHeader.keys().next().value != "custom-type" &&
        modifiedHeader.values().next().value != "upload"
      ) {
        modifiedHeader.append("Content-Type", "application/json")
      }
      const pop = `PoP ${accessToken}`
      modifiedHeader.delete("Authorization")
      modifiedHeader.append("Authorization", pop)
      config.headers = modifiedHeader
    }
    return [url, config]
  },

  requestError: async function (error) {
    // Called when an error occured during another 'request' interceptor call
    return Promise.reject(error)
  },

  response: async function (response) {
    if (response.status === 401) {
      // console.log("Hello","I am intercepted")
      deleteAllCookies()
      localStorage.clear()
      window.location.href = "/"
    }
    if (
      response.request.url.includes("/oauth2/v2.0/token") &&
      response.status === 400
    ) {
      deleteAllCookies()
      localStorage.clear()
      window.location.href = "/"
    }
    if (response.status === 404) {
      // console.log("you do not having right permission to perform this request.")
    }
    return response
  },

  responseError: async function (error) {
    // Handle an fetch error
    return Promise.reject(error)
  },
})
