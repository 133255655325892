import {
  ADD_TO_CART,
  ADD_ALGO_TO_CART,
  ADD_SOLN_TO_CART,
  ADD_DATA_TO_CART,
  ADD_UX_TO_CART,
  DELETE_DATA_TO_CART,
  DELETE_ALGO_TO_CART,
  DELETE_SOLN_TO_CART,
  DELETE_UX_TO_CART,
  RESET_TO_CART
} from "./actionTypes"

const INIT_STATE = {
  algocart: [],
  datacart: [],
  solncart: [],
  uxcart:[],
  error: {},
}

const DevStudioCart = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_ALGO_TO_CART:
      return {
        ...state,
        algocart: [...state.algocart, action.payload],
      }
    case ADD_DATA_TO_CART:
      return {
        ...state,
        datacart: [...state.datacart, action.payload],
      }
    case ADD_SOLN_TO_CART:
      return {
        ...state,
        solncart: [action.payload],
      }
      case ADD_UX_TO_CART:
        return {
          ...state,
          uxcart: [...state.uxcart, action.payload],
        }

    case DELETE_DATA_TO_CART:
      return {
        ...state,
        datacart: action.payload      
      }
    case DELETE_ALGO_TO_CART:
      return {
        ...state,
        algocart: action.payload      
      }
    case DELETE_SOLN_TO_CART:
      return {
        ...state,
        solncart: action.payload     
      }

    case DELETE_UX_TO_CART:
        return {
          ...state,
          uxcart: action.payload      
        }
        case RESET_TO_CART:
          return {
            ...state,
            algocart: [],
  datacart: [],
  solncart: [],
  uxcart:[],    
          }

    default:
      return state
  }
}

export default DevStudioCart
