import PropTypes from "prop-types"
import React, { useEffect, useState, useCallback } from "react"
import { sendEvent } from "../../../src/utils/sendEvent"
import { getBrowserInfo } from "../../../src/utils/getBrowserInfo"

import { withRouter } from "react-router-dom"
import {
  changeLayout,
  changeSidebarTheme,
  changeSidebarThemeImage,
  changeSidebarType,
  changeTopbarTheme,
  changeLayoutWidth,
} from "../../store/actions"

// Layout Related Components
import Header from "./Header"
import Sidebar from "./Sidebar"
import Footer from "./Footer"
import Rightbar from "../CommonForBoth/RightSidebar"
import { useMsal } from "@azure/msal-react"
//redux
import { useSelector, useDispatch } from "react-redux"
import { userCreation } from "userLog"
import { protectedResources } from "../../authConfig"
import account from "store/auth/register/reducer"
import useAdminStore from "../../../src/store/zustand/admin"
const Layout = props => {
  const [admin, setAdmin] = useState([])
  const dispatch = useDispatch()
  const { accounts } = useMsal()
  let name = ""
  let email = ""
  if (accounts && accounts.length) {
    name = accounts[0]["name"]
    email = accounts[0]["username"]
  }
  const {
    isPreloader,
    leftSideBarThemeImage,
    layoutWidth,
    leftSideBarType,
    topbarTheme,
    showRightSidebar,
    leftSideBarTheme,
  } = useSelector(state => ({
    isPreloader: state.Layout.isPreloader,
    leftSideBarThemeImage: state.Layout.leftSideBarThemeImage,
    leftSideBarType: state.Layout.leftSideBarType,
    layoutWidth: state.Layout.layoutWidth,
    topbarTheme: state.Layout.topbarTheme,
    showRightSidebar: state.Layout.showRightSidebar,
    leftSideBarTheme: state.Layout.leftSideBarTheme,
  }))

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

  const toggleMenuCallback = () => {
    if (leftSideBarType === "default") {
      dispatch(changeSidebarType("condensed", isMobile))
    } else if (leftSideBarType === "condensed") {
      dispatch(changeSidebarType("default", isMobile))
    }
  }

  /*
  layout  settings
  */

  useEffect(() => {
    if (isPreloader === true) {
      document.getElementById("preloader").style.display = "block"
      document.getElementById("status").style.display = "block"

      setTimeout(function () {
        document.getElementById("preloader").style.display = "none"
        document.getElementById("status").style.display = "none"
      }, 2500)
    } else {
      document.getElementById("preloader").style.display = "none"
      document.getElementById("status").style.display = "none"
    }
  }, [isPreloader])

  const checkUser = async email => {
    try {
      const queryParams = new URLSearchParams({ email })
      const options = {
        method: "GET",
      }
      const response = await fetch(
        `${protectedResources.apiTodoList.endpoint}/users/checkuser?${queryParams}`,
        options,
      )
      if (!response.ok) {
        throw new Error("Failed to fetch user data")
      }
      return await response.json()
    } catch (error) {
      console.error("Error fetching user data:", error)
      throw error
    }
  }

  useEffect(() => {
    if (!localStorage.getItem("accounts")) {
      localStorage.setItem("accounts", JSON.stringify(accounts))

      checkUser(accounts[0]?.username)
        .then(async data => {
          const eventUuid = await sendEvent(
            "user_logged_in",
            accounts[0]?.username,
            {
              name: accounts[0]?.name,
              browser_details: getBrowserInfo(),
              broswer_timezone:
                Intl.DateTimeFormat().resolvedOptions().timeZone,
              is_first_login: data.isFirstTimeLogin,
            },
          )
          localStorage.setItem("uuid", eventUuid.uuid)
        })
        .catch(error => {
          console.error("Error checking user login:", error)
        })
    }
  }, [accounts])

  useEffect(() => {
    dispatch(changeLayout("vertical"))
  }, [dispatch])

  useEffect(() => {
    if (leftSideBarTheme) {
      dispatch(changeSidebarTheme(leftSideBarTheme))
    }
  }, [leftSideBarTheme, dispatch])

  useEffect(() => {
    if (leftSideBarThemeImage) {
      dispatch(changeSidebarThemeImage(leftSideBarThemeImage))
    }
  }, [leftSideBarThemeImage, dispatch])

  useEffect(() => {
    if (layoutWidth) {
      dispatch(changeLayoutWidth(layoutWidth))
    }
  }, [layoutWidth, dispatch])

  useEffect(() => {
    if (leftSideBarType) {
      dispatch(changeSidebarType(leftSideBarType))
    }
  }, [leftSideBarType, dispatch])

  useEffect(() => {
    if (topbarTheme) {
      dispatch(changeTopbarTheme(topbarTheme))
    }
  }, [topbarTheme, dispatch])

  useEffect(() => {
    getAdminDetails()
  }, [])

  const getAdminDetails = useCallback(async () => {
    const options = {
      method: "GET",
    }
    return fetch(
      protectedResources.apiTodoList.endpoint + `/users/admin/${email}`,
      options,
    )
      .then(response => response.json())
      .then(response => {
        if (response.length > 0) setAdmin(response[0].role_name)
      })
      .catch(error => console.log(error))
  })
  // console.log(admin, "role")
  const { adminData, setAdminData } = useAdminStore()
  useEffect(() => {
    setAdminData(admin)
  }, [admin, setAdminData])
  // console.log(adminData, "zustand")
  return (
    <React.Fragment>
      <div id="preloader">
        <div id="status">
          <div className="spinner-chase">
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
          </div>
        </div>
      </div>

      <div id="layout-wrapper">
        <Header
          toggleMenuCallback={toggleMenuCallback}
          breadcrumb={props.breadcrumb}
          admin={admin}
        />
        <Sidebar
          admin={admin}
          theme={leftSideBarTheme}
          type={leftSideBarType}
          isMobile={isMobile}
        />
        <div className="main-content">{props.children}</div>
        {/* <Footer /> */}
      </div>
      {showRightSidebar ? <Rightbar /> : null}
    </React.Fragment>
  )
}

Layout.propTypes = {
  changeLayoutWidth: PropTypes.func,
  changeSidebarTheme: PropTypes.func,
  changeSidebarThemeImage: PropTypes.func,
  changeSidebarType: PropTypes.func,
  changeTopbarTheme: PropTypes.func,
  children: PropTypes.object,
  isPreloader: PropTypes.any,
  layoutWidth: PropTypes.any,
  leftSideBarTheme: PropTypes.any,
  leftSideBarThemeImage: PropTypes.any,
  leftSideBarType: PropTypes.any,
  location: PropTypes.object,
  showRightSidebar: PropTypes.any,
  topbarTheme: PropTypes.any,
}

export default withRouter(Layout)
