import {
  ADD_DATASET_DETAILS,
  ADD_DATASET,
  ADD_DDL,
  ADD_ERD,
  ADD_WIKI,
  ADD_TOTAL,
  RESET_TO_DATASET,
} from "./actionTypes"

const INIT_STATE = {
  dataset_details: [],
  dataset: "",
  ddl: "",
  erd: "",
  wiki: "",
  totalSize:''
}

const DataMarket = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_DATASET_DETAILS:
      return {
        ...state,
        dataset_details:  action.payload
      }
    case ADD_DATASET:
      return {
        ...state,
        dataset: action.payload,
      }
    case ADD_DDL:
      return {
        ...state,
        ddl: action.payload,
      }
    case ADD_ERD:
      return {
        ...state,
        erd: action.payload,
      }

    case ADD_WIKI:
      return {
        ...state,
        wiki: action.payload,
      }

      case ADD_TOTAL:
        return {
          ...state,
          totalSize: [...state.totalSize, action.payload],
        }
    case RESET_TO_DATASET:
      return {
        ...state,
        dataset_details: [],
        dataset: [],
        ddl: [],
        erd: [],
        wiki: [],
        totalSize:[]
      }

    default:
      return state
  }
}

export default DataMarket
