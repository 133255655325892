import React, { useState } from "react";
import { MetaTags } from "react-meta-tags";
function HeaderMeta(props) {
    var title = props.title ?? 'ATOM.AI';
    title+=' | Studio Platform'
    return (
        <React.Fragment>
            <MetaTags>
                <title>{`${title ? title : ''}`}</title>
            </MetaTags>
        </React.Fragment>
    )
}
export default HeaderMeta;