import axios from "axios"
import accessToken from "./jwt-token-access/accessToken"

//pass new generated access token here
import { getToken } from "./../getToken"

//apply base url for axios
const API_URL = ""

const axiosApi = axios.create({
  baseURL: API_URL,
})

// axiosApi.defaults.headers.common["Authorization"] = token
axiosApi.interceptors.request.use(async function (config) {
  const accessToken = await getToken(config.method, `/moat/searchResult`)
  const pop = `PoP ${accessToken}`
  config.headers.Authorization =  pop;
  return config;
});

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response =>response.data)
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}
