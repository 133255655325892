let redirectUrl = [
  {
    name: "Discover Atom",
    url: "/moat/home",
  },
   {
    name: "My Workspace",
    url: "/my-workspace",
  },
  // {
  //   name: "My Solutions",
  //   url: "/my-workspace/solutions/grid",
  // },
  // {
  //   name: "My Assets",
  //   url: "/my-workspace/assets/list",
  // },
  {
    name: "Feature Store",
    url: "/feature-store/introduction",
  },
  {
    name: "Dev Tools",
    url: "/dev-tools",
  },
  // {
  //   name: "Automation Intelligence",
  //   url: "/di-tools",
  // },
  {
    name: "Data Marketplace",
    url: "/data-market-place",
  },
  {
    name: "Enterprise Data Model Library",
    url: "/enterprise-data-model",
  },
]

export { redirectUrl }
