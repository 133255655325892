import React, { useState, useEffect } from "react"
import styles from "./topbar.module.scss"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledTooltip,
  Row,
  Col,
} from "reactstrap"
//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import { useMsal } from "@azure/msal-react"
import Avatar from "react-avatar"
// users

const ProfileMenu = props => {
  const { instance, accounts } = useMsal()
  const { username, setusername } = useState()
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)

  let name = "";
  let email = "";
  if (accounts && accounts.length) {
    name = accounts[0]["name"]
    email = accounts[0]["username"]
  }

  let avtarText = ""
  if (name) {
    for (var i = 0; i < name.split(" ").length; i++) {
      avtarText += name.split(" ")[i].slice(0, 1)
    }
  }

  const handleLogout = logoutType => {
    if (logoutType === "popup") {
      instance.logoutPopup({
        postLogoutRedirectUri: "/logout",
        mainWindowRedirectUri: "",
      })
    } else if (logoutType === "redirect") {
      instance.logoutRedirect({
        postLogoutRedirectUri: "/logout",
      })
    }
    localStorage.removeItem('persist:cart');
  }

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        setusername(obj.displayName)
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        setusername(obj.username)
      }
    }
  }, [props.success])

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item d-flex align-items-center topnotiIcon"
          id="page-header-user-dropdown"
          tag="button"
        >
          {/* <Avatar
            className="nameAvatar"
            round="100%"
            size="35"
            color="#FFFFFF"
            fgColor="000000"
            name={name}
          /> */}
          <div id={"avtar-name"} className={`${styles.customAvatar}`}>
            {avtarText}
            <UncontrolledTooltip placement="bottom" target={"avtar-name"}>
              {name}
            </UncontrolledTooltip>
          </div>

          {/* <span className="d-none d-xl-inline-block ms-2 me-1">{name}</span> */}
          <i
            style={{ fontSize: "1.4em" }}
            className="mdi mdi-chevron-down d-none d-xl-inline-block"
          />
        </DropdownToggle>
        <DropdownMenu
          className="dropdown-menu-end"
          style={{ width: "24rem", padding: "15px" }}
        >
          <Row>
            <Col md={4} className="d-flex justify-content-center p-0 mt-3">
              <Avatar
                round="100%"
                size="90"
                color="#FE5000"
                fgColor="#FFFFFF"
                name={name}
              />
            </Col>
            <Col md={8}>
              <div className="d-flex flex-row-reverse">
                <i
                  style={{ cursor: "pointer" }}
                  className="bx bx-power-off font-size-24 align-middle me-1 text-danger"
                  onClick={() => handleLogout("popup")}
                ></i>
              </div>
              <h5>{name}</h5>
              <h6>{email}</h6>
              <h6 style={{ fontWeight: '800', color: '#fe5000' }}>{props.admin}</h6>
              <br />
              {/* <DropdownItem tag="a" href="/profile">
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1" />
            {props.t("Profile")}{" "}
          </DropdownItem> */}
              {/* <DropdownItem tag="a" href="/dashboard">
            {" "}
            <i className="bx bxs-dashboard font-size-16 align-middle me-1" />
            {props.t("Dashboard")}{" "}
          </DropdownItem> */}

              <DropdownItem
                tag={Link}
                to={"/my-workspace/solutions/grid"}
                style={{ padding: "0" }}
              >
                <i className="bx bx-briefcase font-size-16 align-middle me-1  " />
                {props.t("My Solutions")}
              </DropdownItem>
              <div className="dropdown-divider mt-2"></div>
              {/* <DropdownItem
                tag={Link}
                to={"/my-workspace/assets/list"}
                style={{ padding: "0" }}
              >
                <i className="bx bx-sync font-size-16 align-middle me-1" />
                {props.t("My Assets")}
              </DropdownItem> */}
              {/* <DropdownItem tag="a" href="#">
            <span className="badge bg-success float-end">11</span>
            <i className="bx bx-wrench font-size-16 align-middle me-1" />
            {props.t("Settings")}
          </DropdownItem> */}
              {/* <DropdownItem tag="a" href="auth-lock-screen">
            <i className="bx bx-lock-open font-size-16 align-middle me-1" />
            {props.t("Lock screen")}
          </DropdownItem> */}
              {/* <div className="dropdown-divider mt-2" /> */}
              {/* <button
                style={{ padding: "0" }}
                onClick={() => handleLogout("popup")}
                className="dropdown-item"
              >
                
                <span>{props.t("Logout")}</span>
              </button> */}
            </Col>
          </Row>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
)
