const yearData = [
    {
        name: "Concept",
        data: [44, 55, 41, 67, 22, 43]
    },
    {
        name: "Development",
        data: [13, 23, 20, 8, 13, 27]
    },
    {
        name: "Deployment",
        data: [11, 17, 15, 15, 21, 14]
    },
];

const monthData = [
    {
        name: "Concept",
        data: [34, 55, 21, 77, 32, 63],
    },
    {
        name: "Development",
        data: [10, 63, 40, 80, 52, 41],
    },
    {
        name: "Deployment",
        data: [11, 17, 15, 85, 21, 14],
    },
];

const weekData = [
    {
        name: "Concept",
        data: [14, 52, 11, 57, 22, 33, 31, 22, 64, 14, 32, 68],
    },
    {
        name: "Development",
        data: [13, 23, 20, 8, 13, 27, 18, 22, 10, 16, 24, 22],
    },
    {
        name: "Deployment",
        data: [11, 17, 15, 15, 34, 55, 21, 18, 17, 12, 20, 18],
    },
];

export { yearData, monthData, weekData }