const projects = [
  {
    id: 0,
    img: "img1",
    name: "Customer Explorer",
    description:
      "Allow users to create custom segments using rule-based approach or behavioral segments using Unsupervised ML",
    status: "Completed",
    color: "success",
    dueDate: "2021-7-15",
    commentsCount: 21,
    team: [
      {
        id: 1,
        img: "Null",
        name: "A",
        fullname: "Akshay",
        skills: [
          { id: 1, name: "Frontend" },
          { id: 2, name: "UI" },
        ],
      },
      {
        id: 2,
        img: "Null",
        name: "K",
        fullname: "Kotresh",
        skills: [{ id: 1, name: "Frontend" }],
      },
      {
        id: 3,
        img: "Null",
        name: "A",
        color: "success",
        fullname: "Abhishek",
        skills: [{ id: 1, name: "Backend" }],
      },
    ],
    startDate: "08 Sept, 2020",
    projectDetails: {
      description:
        "Customer Explorer allows marketers and CX practitioners to create segments based on Customer 360 (behavior, profile, predictions & third-party data). The solution supports marketers with data-driven segmentation and targeting. Further, it can be integrated with existing campaign & marketing tools.",
      points: [
        "End-To-End Analytics For Marketers",
        "Easy Conversion Of Data Product To Insights Product.",
        "Reduced Ad-Hoc Work For CX.",
      ],
    },
    files: [
      { name: "atom.ai Landing.Zip", size: "3.25 MB", link: "#" },
      { name: "atom.ai Admin.Zip", size: "3.15 MB", link: "#" },
      { name: "atom.ai Logo.Zip", size: "2.02 MB", link: "#" },
      { name: "Veltrix admin.Zip", size: "2.25 MB", link: "#" },
    ],
    comments: [
      {
        id: 1,
        username: "David Lambert",
        userImg: "avatar2",
        comment: "Separate existence is a myth.",
      },
      {
        id: 2,
        username: "Steve Foster",
        userImg: "avatar3",
        comment: "@Henry To an English person it will like simplified",
        reply: {
          username: "Jeffrey Walker",
          comment: "as a skeptical Cambridge friend",
        },
      },
      {
        id: 3,
        username: "Steven Carlson",
        comment: " Separate existence is a myth.",
      },
    ],
  },
  {
    id: 3,
    img: "img2",
    name: "Rebate Analytics",
    description:
      "This platform is a one stop shop to manage all the deals, approvals and rebate calculation",
    status: "Pending",
    color: "warning",
    dueDate: "2022-10-22",
    commentsCount: 183,
    team: [
      { id: 1, img: "Null", name: "P", fullname: "Prashant" },
      { id: 2, img: "Null", name: "J", fullname: "Jai" },
    ],
    startDate: "10 Sep, 2021",
    projectDetails: {
      description:
        "Rebate.ai (Rebate Analytics Platform) aims at making complex deal management easy for buying groups and its members. This system aims to boost operational efficiency and financial transparency. With Rebate.ai, Suppliers can model the most complex rebate deals with ease, members can identify the best rebate deals and Buying Group corporate can easily reconcile the payout and keep a trail of the rebate audit.",

      points: [],
    },
    files: [
      { name: "atom.ai Landing.Zip", size: "3.25 MB", link: "#" },
      { name: "atom.ai Admin.Zip", size: "3.15 MB", link: "#" },
      { name: "atom.ai Logo.Zip", size: "2.02 MB", link: "#" },
      { name: "Veltrix admin.Zip", size: "2.25 MB", link: "#" },
    ],
    comments: [
      {
        id: 1,
        username: "David Lambert",
        userImg: "avatar2",
        comment: "Separate existence is a myth.",
      },
      {
        id: 2,
        username: "Steve Foster",
        userImg: "avatar3",
        comment: "@Henry To an English person it will like simplified",
        reply: {
          username: "Jeffrey Walker",
          comment: "as a skeptical Cambridge friend",
        },
      },
      {
        id: 3,
        username: "Steven Carlson",
        comment: " Separate existence is a myth.",
      },
    ],
  },
  {
    id: 1,
    img: "img2",
    name: "Anomaly Detection & Hypothesis Generation",
    description:
      "AI/ML solution that helps businesses spot patterns, surface anomalies, and create proactive alerts and resolution",
    status: "Pending",
    color: "warning",
    dueDate: "2019-10-22",
    commentsCount: 183,
    team: [
      { id: 1, img: "Null", name: "P", fullname: "Prashant" },
      { id: 2, img: "Null", name: "D", fullname: "Daniel Candles" },
    ],
    startDate: "08 Sept, 2019",
    projectDetails: {
      description:
        "Anomaly Detection & Hypothesis Generation aims to identify opportunities to improve and adapt to any anomalies within a specific time. Anomaly Detection & Hypothesis Generation built on state-of-the-art, proprietary AI/ML algorithms uses cloud resources to scale up anomaly detection on Big Data.The users can create and update analytical datasets, toolkits, identify anomalies, and even create human-readable statistical explanations and hypotheses to take corrective actions.",
      points: ["Statistical Explanation", "Hypothesis Generation."],
    },
    files: [
      { name: "atom.ai Landing.Zip", size: "3.25 MB", link: "#" },
      { name: "atom.ai Admin.Zip", size: "3.15 MB", link: "#" },
      { name: "atom.ai Logo.Zip", size: "2.02 MB", link: "#" },
      { name: "Veltrix admin.Zip", size: "2.25 MB", link: "#" },
    ],
    comments: [
      {
        id: 1,
        username: "David Lambert",
        userImg: "avatar2",
        comment: "Separate existence is a myth.",
      },
      {
        id: 2,
        username: "Steve Foster",
        userImg: "avatar3",
        comment: "@Henry To an English person it will like simplified",
        reply: {
          username: "Jeffrey Walker",
          comment: "as a skeptical Cambridge friend",
        },
      },
      {
        id: 3,
        username: "Steven Carlson",
        comment: " Separate existence is a myth.",
      },
    ],
  },
  {
    id: 2,
    img: "img3",
    name: "Commerce intelligence - Odyssey",
    description:
      "An AI-led solution for e-commerce businesses to understand customer intelligence and elevate business performance.",
    status: "Delay",
    color: "danger",
    dueDate: "2019-10-13",
    commentsCount: 175,
    team: [
      {
        id: 1,
        img: "Null",
        name: "K",
        color: "info",
        fullname: "Kony Brafford",
      },
      { id: 2, img: "avatar2", fullname: "Daniel Candles" },
    ],
    startDate: "08 Sept, 2019",
    projectDetails: {
      description:
        "Odyssey provides organizations with an accurate and comprehensive overview of its online eCommerce platform. Ecommerce companies can now get a holistic view of customers, suppliers, and everything related to company inventory management.Odyssey unifies all data sources with multiple customer viewpoints and creates a harmonized customer data lake that provides a 360-degree view of the customer’s digital presence across the retail outlets and CPGs. It helps to understand sales trends and driving factors, provides targeted recommendations at the SKU level, and simulates the targets required for various business levers.",
      points: [
        "Sales Health",
        "Separate existence is a mythRatings.",
        "Content Health",
      ],
    },
    files: [
      { name: "atom.ai Landing.Zip", size: "3.25 MB", link: "#" },
      { name: "atom.ai Admin.Zip", size: "3.15 MB", link: "#" },
      { name: "atom.ai Logo.Zip", size: "2.02 MB", link: "#" },
      { name: "Veltrix admin.Zip", size: "2.25 MB", link: "#" },
    ],
    comments: [
      {
        id: 1,
        username: "David Lambert",
        userImg: "avatar2",
        comment: "Separate existence is a myth.",
      },
      {
        id: 2,
        username: "Steve Foster",
        userImg: "avatar3",
        comment: "@Henry To an English person it will like simplified",
        reply: {
          username: "Jeffrey Walker",
          comment: "as a skeptical Cambridge friend",
        },
      },
      {
        id: 3,
        username: "Steven Carlson",
        comment: " Separate existence is a myth.",
      },
    ],
  },
  {
    id: 4,
    img: "img1",
    name: "Cosmos",
    description:
      "Allow users to Explore the 360 degree profile for a customer.",
    status: "Completed",
    color: "success",
    dueDate: "2021-7-15",

    team: [
      {
        id: 1,
        img: "Null",
        name: "N",
        fullname: "Naman",
        skills: [
          { id: 1, name: "Frontend" },
          { id: 2, name: "UI" },
        ],
      },
      {
        id: 2,
        img: "Null",
        name: "K",
        fullname: "Kotresh",
        skills: [{ id: 1, name: "Frontend" }],
      },
      {
        id: 3,
        img: "Null",
        name: "P",
        color: "success",
        fullname: "pranay",
        skills: [{ id: 1, name: "Backend" }],
      },
    ],
    startDate: "08 Sept, 2020",
    projectDetails: {
      description:
        "Cosmos allows marketers and CX practitioners to create segments based on Customer 360 (behavior, profile, predictions & third-party data). The solution supports marketers with data-driven segmentation and targeting. Further, it can be integrated with existing campaign & marketing tools.",
      points: [
        "End-To-End Analytics For Marketers",
        "Easy Conversion Of Data Product To Insights Product.",
        "Reduced Ad-Hoc Work For CX.",
      ],
    },
    files: [
      { name: "atom.ai Landing.Zip", size: "3.25 MB", link: "#" },
      { name: "atom.ai Admin.Zip", size: "3.15 MB", link: "#" },
      { name: "atom.ai Logo.Zip", size: "2.02 MB", link: "#" },
      { name: "Veltrix admin.Zip", size: "2.25 MB", link: "#" },
    ],
    comments: [
      {
        id: 1,
        username: "David Lambert",
        userImg: "avatar2",
        comment: "Separate existence is a myth.",
      },
      {
        id: 2,
        username: "Steve Foster",
        userImg: "avatar3",
        comment: "@Henry To an English person it will like simplified",
        reply: {
          username: "Jeffrey Walker",
          comment: "as a skeptical Cambridge friend",
        },
      },
      {
        id: 3,
        username: "Steven Carlson",
        comment: " Separate existence is a myth.",
      },
    ],
  },
  // {
  //   id: 3,
  //   img: "img4",
  //   name: "Redesign - Landing page",
  //   description: "If several languages coalesce",
  //   status: "Completed",
  //   color: "success",
  //   dueDate: "2019-10-14",
  //   commentsCount: 202,
  //   team: [
  //     { id: 1, img: "avatar4", fullname: "Janice Cole" },
  //   ],
  //   startDate: "08 Sept, 2019",
  //   projectDetails: {
  //     description:
  //       "To an English person, it will seem like simplified English, as a skeptical Cambridge friend of mine told me what Occidental is. The European languages are members of the same family. Their separate existence is a myth. For science, music, sport, etc,",
  //     points: [
  //       "To achieve this, it would be necessary",
  //       "Separate existence is a myth.",
  //       "If several languages coalesce",
  //     ],
  //   },
  //   files: [
  //     { name: "atom.ai Landing.Zip", size: "3.25 MB", link: "#" },
  //     { name: "atom.ai Admin.Zip", size: "3.15 MB", link: "#" },
  //     { name: "atom.ai Logo.Zip", size: "2.02 MB", link: "#" },
  //     { name: "Veltrix admin.Zip", size: "2.25 MB", link: "#" },
  //   ],
  //   comments: [
  //     {
  //       id: 1,
  //       username: "David Lambert",
  //       userImg: "avatar2",
  //       comment: "Separate existence is a myth.",
  //     },
  //     {
  //       id: 2,
  //       username: "Steve Foster",
  //       userImg: "avatar3",
  //       comment: "@Henry To an English person it will like simplified",
  //       reply: {
  //         username: "Jeffrey Walker",
  //         comment: "as a skeptical Cambridge friend",
  //       },
  //     },
  //     {
  //       id: 3,
  //       username: "Steven Carlson",
  //       comment: " Separate existence is a myth.",
  //     },
  //   ],
  // },
  // {
  //   id: 4,
  //   img: "img5",
  //   name: "atom.ai Dashboard UI",
  //   description: "Separate existence is a myth",
  //   status: "Completed",
  //   color: "success",
  //   dueDate: "2019-10-13",
  //   commentsCount: 194,
  //   team: [
  //     { id: 1, img: "avatar1", fullname: "Jennifer Walker" },
  //     { id: 2, img: "avatar3", fullname: "Daniel Candel" },
  //     { id: 3, img: "Null", name: "3+", color: "danger" },
  //   ],
  //   startDate: "08 Sept, 2019",
  //   projectDetails: {
  //     description:
  //       "To an English person, it will seem like simplified English, as a skeptical Cambridge friend of mine told me what Occidental is. The European languages are members of the same family. Their separate existence is a myth. For science, music, sport, etc,",
  //     points: [
  //       "To achieve this, it would be necessary",
  //       "Separate existence is a myth.",
  //       "If several languages coalesce",
  //     ],
  //   },
  //   files: [
  //     { name: "atom.ai Landing.Zip", size: "3.25 MB", link: "#" },
  //     { name: "atom.ai Admin.Zip", size: "3.15 MB", link: "#" },
  //     { name: "atom.ai Logo.Zip", size: "2.02 MB", link: "#" },
  //     { name: "Veltrix admin.Zip", size: "2.25 MB", link: "#" },
  //   ],
  //   comments: [
  //     {
  //       id: 1,
  //       username: "David Lambert",
  //       userImg: "avatar2",
  //       comment: "Separate existence is a myth.",
  //     },
  //     {
  //       id: 2,
  //       username: "Steve Foster",
  //       userImg: "avatar3",
  //       comment: "@Henry To an English person it will like simplified",
  //       reply: {
  //         username: "Jeffrey Walker",
  //         comment: "as a skeptical Cambridge friend",
  //       },
  //     },
  //     {
  //       id: 3,
  //       username: "Steven Carlson",
  //       comment: " Separate existence is a myth.",
  //     },
  //   ],
  // },
   {
    id: 5,
    img: "img6",
    name: "Cosmos",
    description: "Allow users to Explore the 360 degree profile for a customer.",
    status: "Pending",
    color: "warning",
    dueDate: "2019-10-24",
    commentsCount: 222,
    team: [
      { id: 1, img: "avatar4", fullname: "Janice Cole" },
      { id: 2, img: "avatar5", fullname: "Steve Foster" },
    ],
    startDate: "08 Sept, 2019",
    projectDetails: {
      description:
        "Cosmos allows marketers and CX practitioners to create segments based on Customer 360 (behavior, profile, predictions & third-party data). The solution supports marketers with data-driven segmentation and targeting. Further, it can be integrated with existing campaign & marketing tools.",
      points: [
        "To achieve this, it would be necessary",
        "Separate existence is a myth.",
        "If several languages coalesce",
      ],
    },
    files: [
      { name: "atom.ai Landing.Zip", size: "3.25 MB", link: "#" },
      { name: "atom.ai Admin.Zip", size: "3.15 MB", link: "#" },
      { name: "atom.ai Logo.Zip", size: "2.02 MB", link: "#" },
      { name: "Veltrix admin.Zip", size: "2.25 MB", link: "#" },
    ],
    comments: [
      {
        id: 1,
        username: "David Lambert",
        userImg: "avatar2",
        comment: "Separate existence is a myth.",
      },
      {
        id: 2,
        username: "Steve Foster",
        userImg: "avatar3",
        comment: "@Henry To an English person it will like simplified",
        reply: {
          username: "Jeffrey Walker",
          comment: "as a skeptical Cambridge friend",
        },
      },
      {
        id: 3,
        username: "Steven Carlson",
        comment: " Separate existence is a myth.",
      },
    ],
  }
  // {
  //   id: 6,
  //   img: "img3",
  //   name: "Multipurpose Landing",
  //   description: "It will be as simple as Occidental",
  //   status: "Delay",
  //   color: "danger",
  //   dueDate: "2019-10-15",
  //   commentsCount: 214,
  //   team: [
  //     { id: 1, img: "avatar5", fullname: "Steve Foster" },
  //     { id: 3, img: "Null", name: "R", color: "warning", fullname: "Rony Candles" },
  //   ],
  // },
  // {
  //   id: 7,
  //   img: "img4",
  //   name: "App Landing UI",
  //   description: "It will be as simple as Occidental",
  //   status: "Completed",
  //   color: "success",
  //   dueDate: "11 Oct, 19",
  //   commentsCount: 185,
  //   team: [
  //     { id: 1, img: "Null", name: "L", color: "pink", fullname: "Lony Mackay" },
  //     { id: 3, img: "avatar2", fullname: "Daniel Candles" },
  //   ],
  //   startDate: "08 Sept, 2019",
  //   projectDetails: {
  //     description:
  //       "To an English person, it will seem like simplified English, as a skeptical Cambridge friend of mine told me what Occidental is. The European languages are members of the same family. Their separate existence is a myth. For science, music, sport, etc,",
  //     points: [
  //       "To achieve this, it would be necessary",
  //       "Separate existence is a myth.",
  //       "If several languages coalesce",
  //     ],
  //   },
  //   files: [
  //     { name: "atom.ai Landing.Zip", size: "3.25 MB", link: "#" },
  //     { name: "atom.ai Admin.Zip", size: "3.15 MB", link: "#" },
  //     { name: "atom.ai Logo.Zip", size: "2.02 MB", link: "#" },
  //     { name: "Veltrix admin.Zip", size: "2.25 MB", link: "#" },
  //   ],
  //   comments: [
  //     {
  //       id: 1,
  //       username: "David Lambert",
  //       userImg: "avatar2",
  //       comment: "Separate existence is a myth.",
  //     },
  //     {
  //       id: 2,
  //       username: "Steve Foster",
  //       userImg: "avatar3",
  //       comment: "@Henry To an English person it will like simplified",
  //       reply: {
  //         username: "Jeffrey Walker",
  //         comment: "as a skeptical Cambridge friend",
  //       },
  //     },
  //     {
  //       id: 3,
  //       username: "Steven Carlson",
  //       comment: " Separate existence is a myth.",
  //     },
  //   ],
  // },
  // {
  //   id: 8,
  //   img: "img2",
  //   name: "New admin Design",
  //   description: "Their most common words.",
  //   status: "Completed",
  //   color: "success",
  //   dueDate: "2019-10-12",
  //   commentsCount: 106,
  //   team: [
  //     { id: 1, img: "avatar4", fullname: "Janice Cole" },
  //     { id: 2, img: "avatar5", fullname: "Steve Foster" },
  //     { id: 3, img: "Null", name: "A", color: "success", fullname: "Aeffrey Walker" },
  //     { id: 3, img: "avatar2", fullname: "Daniel Candles" },
  //   ],
  //   startDate: "08 Sept, 2019",
  //   projectDetails: {
  //     description:
  //       "To an English person, it will seem like simplified English, as a skeptical Cambridge friend of mine told me what Occidental is. The European languages are members of the same family. Their separate existence is a myth. For science, music, sport, etc,",
  //     points: [
  //       "To achieve this, it would be necessary",
  //       "Separate existence is a myth.",
  //       "If several languages coalesce",
  //     ],
  //   },
  //   files: [
  //     { name: "atom.ai Landing.Zip", size: "3.25 MB", link: "#" },
  //     { name: "atom.ai Admin.Zip", size: "3.15 MB", link: "#" },
  //     { name: "atom.ai Logo.Zip", size: "2.02 MB", link: "#" },
  //     { name: "Veltrix admin.Zip", size: "2.25 MB", link: "#" },
  //   ],
  //   comments: [
  //     {
  //       id: 1,
  //       username: "David Lambert",
  //       userImg: "avatar2",
  //       comment: "Separate existence is a myth.",
  //     },
  //     {
  //       id: 2,
  //       username: "Steve Foster",
  //       userImg: "avatar3",
  //       comment: "@Henry To an English person it will like simplified",
  //       reply: {
  //         username: "Jeffrey Walker",
  //         comment: "as a skeptical Cambridge friend",
  //       },
  //     },
  //     {
  //       id: 3,
  //       username: "Steven Carlson",
  //       comment: " Separate existence is a myth.",
  //     },
  //   ],
  // },
]

const options = {
  chart: {
    height: 290,
    type: "bar",
    toolbar: {
      show: !1,
    },
  },
  plotOptions: {
    bar: {
      columnWidth: "14%",
      endingShape: "rounded",
    },
  },
  dataLabels: {
    enabled: !1,
  },
  series: [
    {
      name: "Overview",
      data: [42, 56, 40, 64, 26, 42, 56, 35, 62],
    },
  ],
  grid: {
    yaxis: {
      lines: {
        show: !1,
      },
    },
  },
  yaxis: {
    title: {
      text: "% (Percentage)",
    },
  },
  xaxis: {
    labels: {
      rotate: -90,
    },
    categories: ["1", "2", "3", "4", "5", "6", "7", "8", "9"],
    title: {
      text: "Week",
    },
  },
  colors: ["#556ee6"],
}

const series = [
  {
    name: "Overview",
    data: [42, 56, 40, 64, 26, 42, 56, 35, 62],
  },
]

export { projects, options, series }
