import React from "react";
import { Spinner } from 'react-bootstrap';

function Loader() {
    var styleConfig = {
        position: 'absolute',
        height: '100%',
        top: '50%',
        left: '50%',
        backgroundSize: '100%',
        zIndex: 99,
        color: '#fe5000'
    };
    return (
        <div className="loader" style={{ ...styleConfig }}>
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>
    );
}

export default Loader;