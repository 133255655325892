/** ADD TO CART*/
export const ADD_DATASET_DETAILS = "ADD_DATASET_DETAILS"
export const ADD_DATASET = "ADD_DATASET"
export const ADD_DDL = "ADD_DDL"
export const ADD_ERD = "ADD_ERD"
export const ADD_WIKI = "ADD_WIKI"
export const RESET_TO_DATASET="RESET_TO_DATASET"
export const ADD_TOTAL = "ADD_TOTAL"

export default {
  ADD_DATASET_DETAILS,
  ADD_DATASET,
  ADD_DDL,
  ADD_ERD,
  ADD_WIKI,
  RESET_TO_DATASET,
  ADD_TOTAL,
}
