import mongodb from "../../assets/images/small/mongodb.png"
import postgres from "../../assets/images/small/postgres.png"
import snowflake from "../../assets/images/small/snowflake.png"
import mysql from "../../assets/images/small/mysql.png"
import airbyte from "../../assets/images/small/airbyte.png"
import azuredb from "../../assets/images/small/azuredatabrick.png"
import hevo from "../../assets/images/small/hevo.png"
import azuredf from "../../assets/images/small/azuredatafactory.png"
import mockaroo from "../../assets/images/small/mockaroo.png"
import hasura from "../../assets/images/small/hasura.png"
import dbt from "../../assets/images/small/dbt.png"
import airflow from "../../assets/images/small/airflow.png"
import powerbi from "../../assets/images/small/powerbi.png"
import superset from "../../assets/images/small/superset.png"
import metabase from "../../assets/images/small/metabase.png"
import grafana from "../../assets/images/small/grafana.png"


const visualTool  = [
    {
      id: 1,
      image: metabase,
      title: "Metabase",
      description:"An open-source alternative to PowerBI and Tableau etc. Packed with powerful charts, this tool works across a variety of database and best of all – is free!"
    },
    {
      id: 2,
      image: powerbi,
      title: "Power BI",
      description:"Not only a visualization tool but a collection of software services, apps, and connectors that work together to turn your unrelated sources of data into coherent, visually immersive, and interactive insights. Comes with a desktop client, an online SaaS service & mobile app."
    },
    {
      id: 3,
      image: superset,
      title: "Super Set",
      description:"Superset is fast, lightweight, intuitive, and loaded with options that make it easy for users of all skill sets to explore and visualize data. This open-source offering is able to handle data at petabyte scale (big data)."
    },
    {
      id: 4,
      image: grafana ,
      title: "Grafana",
      description:"A multi-platform open-source analytics and interactive visualization web application which provides charts, graphs, and alerts for the web."
    },

]

const processingTool  = [
    {
      id: 1,
      image: [hevo],
      title: "Hevo",
      description:"A no-code, bi-directional data pipeline platform built for modern ETL, ELT, and Reverse ETL Needs. It helps data teams streamline and automate org-wide data flows resulting in faster reporting, analytics, and decision making.",
    },
    {
      id: 2,
      image: [azuredf],
      title: "Azure Data Factory",
      description:"A cloud-based ETL and data integration service that allows you to create data-driven workflows for orchestrating data movement and transforming data at scale.",
    },
    {
      id: 3,
      image: [azuredb],
      title: "Azure Databricks",
      description:"A cloud-based data engineering tool used for processing and transforming massive quantities of data and exploring the data through machine learning models. Part of Azure offering",
    },
    {
      id: 4,
      image: [airbyte],
      title: "Airbyte",
      description:"An open-source data integration and syncing engine that helps you consolidate your data in your data warehouses, lakes and databases.",
    },
    {
      id: 5,
      image: [dbt],
      title: "DBT",
      description:"DBT is a tool that aims at facilitating the work of analysts and data engineers in transforming data and modeling within a data warehouse. It provides a command-line as well as a documentation and RPC server.",
    },
    {
      id: 6,
      image: [airflow],
      title: "Airflow",
      description:"Airflow is a platform to programmatically author, schedule and monitor workflows. The airflow scheduler executes your tasks on an array of workers while following the specified dependencies.",
    },
    {
      id: 7,
      image: [hasura],
      title: "Hasura",
      description:"The Hasura GraphQL Engine is a blazing-fast GraphQL server that gives you instant, realtime GraphQL APIs over Postgres, with webhook triggers on database events, and remote schemas for business logic",
    },
    {
      id: 8,
      image: [mockaroo],
      title: "Mocaroo",
      description:"A free test data generator and API mocking tool - Mockaroo lets you create custom CSV, JSON, SQL, and Excel datasets to test and demo your software.",
    },
]
const storageTool  = [
    {
      id: 1,
      image: [snowflake],
      title: "Snow Flake",
      description:"Snowflake enables fast, easy and flexible data storage, processing, and analytic solutions. Its cloud agnostic, with unlimited, seamless scalability across AWS and Microsoft Azure."
    },
    {
      id: 2,
      image: [postgres],
      title: "PostGre SQL",
      description:"PostgreSQL or Postgres, is a widely used free and open-source relational database management system emphasizing extensibility and SQL compliance."
    },
    {
      id: 3,
      image: [mysql],
      title: "My SQL",
      description:"A fully managed database service to deploy cloud-native applications. Comes packed with HeatWave, an integrated, high-performance query accelerator."
    },
    {
      id: 4,
      image: [mongodb],
      title: "Mongo DB",
      description:"Its flexible schema makes it easy to evolve and store data in a way that is easy for programmers to work with. MongoDB is also built to scale up quickly and supports all the main features of modern databases."
    },
]


const erDiagram = [
    {
      id: 1,
      title: "ERD Diagram of E-com",
      description:
        "Some quick example text to build on the card title and make up the bulk of the card content.",
    },
    {
      id: 2,
      title: "ERD Diagram of Retail",
      description:
        "To an English person, it will seem like simplified English, as a skeptical Cambridge",
     
    },
    {
      id: 3,
      title: "ERD Diagram of Health Care",
      description:
        "Everyone realizes why a new common language would be desirable.",
     
    },
    {
      id: 4,
      title: "ER Diagram of Customer data platform",
      description:
        "Some quick example text to build on the card title and make up the bulk of the card&apos;s content."
    },
    {
      id: 5,
      title: "ERD Diagram of Retail Customer",
      description:
        "Some quick example text to build on the card title and make up the bulk of the card content.",
    },
    {
      id: 6,
      title: "ER Diagram of OrderDB ",
      description:
        "Some quick example text to build on the card title and make up the bulk of the card content.",
    },
    {
      id: 7,
      title: "ER Diagram of CPG",
      description:
        "Some quick example text to build on the card title and make up the bulk of the card content.",
    },
    {
      id: 8,
      title: "ER Diagram of Warehouse",
      description:
        "Some quick example text to build on the card title and make up the bulk of the card content.",
    },


  ]

  export { erDiagram, storageTool, processingTool, visualTool} 