import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Link, withRouter, Route } from "react-router-dom"
import { authProtectedRoutes, publicRoutes } from "./../../routes"

const BreadCrumbs = props => {
  
  let breadcrumb = props.breadcrumb

  if (breadcrumb)
    return breadcrumb.parent ? (
      <ol
        className=" breadcrumb m-0"
      >
        <li key={`breadcrumb-t1`} className="breadcrumb-item">
          <Link to={breadcrumb.parent.routePath}>
            {breadcrumb.parent.label}
          </Link>
        </li>
        <li key={`breadcrumb-t2`} className="breadcrumb-item">
          <span>{breadcrumb.label}</span>
        </li>
      </ol>
    ) : (
      <ol className=" breadcrumb m-0 breadcrumbLabel">
        <li key={`breadcrumb-t`} className="breadcrumb-item " style={breadcrumb.label === 'Data MarketPlace' ||'Enterprise Data Model'? {marginLeft : '5px'} : {}}>
          <span>{breadcrumb.label}</span>
        </li>
      </ol>
    )
  else
    return (
      <ol className="breadcrumb m-0 breadcrumbLabel">
        <li key={`breadcrumb-t`} className="breadcrumb-item "></li>
      </ol>
    )
}

export default withRouter(BreadCrumbs)