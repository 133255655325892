import { LogLevel, AuthenticationScheme } from "@azure/msal-browser";
const { REACT_APP_REDIRECT_URL, REACT_APP_NODE_AUTH,REACT_APP_NODE_DM_AUTH , REACT_APP_SCOPES ,REACT_APP_clientId ,REACT_APP_AUTHORITY } = process.env
/**
 * Configuration object to be passed to MSAL instance on creation.
 *
 */

export const msalConfig = {
  auth: {
    clientId: `${REACT_APP_clientId}`,
    authority:
      `${REACT_APP_AUTHORITY}`,

    redirectUri: "/moat/home",
    /*  process.env.NODE_ENV === "production"
        ? `${REACT_APP_REDIRECT_URL}/dashboard`
        : "/dashboard", */
    navigateToLoginRequestUrl: true,
    postLogoutRedirectUri: "/"
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
  },
   system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message)
            return
          case LogLevel.Info:
            // console.info(message)
            return
          case LogLevel.Verbose:
            // console.debug(message)
            return
          case LogLevel.Warning:
            console.warn(message)
            return
        }
      },
    },
  },
}
export const protectedResources = {
  apiTodoList: {
      endpoint: REACT_APP_NODE_AUTH,
      endpoint2:REACT_APP_NODE_DM_AUTH,

      // api://d03173df-4828-4b76-913d-64811d73e9d1/access_as_user"
      scopes: [`${REACT_APP_SCOPES}`],
  },
};

export const loginRequest = {
  scopes: [...protectedResources.apiTodoList.scopes],
  authenticationScheme: AuthenticationScheme.POP,
  resourceRequestMethod: "GET",
  resourceRequestUri: protectedResources.apiTodoList.endpoint,
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 
 */

// export const loginRequest = {
//   scopes: ["User.Read"],
// }

/**
 * Add here the scopes to request when obtaining an access token for MS Graph API. For more information, see:
 */
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
}
