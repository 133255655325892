import { all, call, put, takeEvery } from "redux-saga/effects"
import { useCallback } from "react"

import {
  GET_ALL_NODES,
  GET_NODE_PROP_BY_PROJECT,
  GET_SEGMENT_NODES_BY_PROJ,
  UPDATE_CANVAS_ROW_BY_SEGMENT,
  SEND_APPROVAL_EMAIL,
} from "./actionTypes"
import {
  fetchAllNodes,
  onFetchNodePropByProjIdSuccess,
  setCanvasSegmentNodes,
} from "./reducer"
import { del, get, post } from "helpers/api_helper"
import { protectedResources } from "../../authConfig"
import authHeader from "helpers/jwt-token-access/auth-token-header"
const { REACT_APP_APIKEY, REACT_APP_HASURA_URL, REACT_APP_EMAIL_LOGICAPP } =
  process.env

export const getAllNodes = async () => {
  return await get(protectedResources.apiTodoList.endpoint + `/myProjectNode/tools`)
}
export const getNodePropByProject = async (projectId, compId) => {
  return await get(
    protectedResources.apiTodoList.endpoint +
    `/hasura/projects/tools/${projectId}/${compId}`
  )
}

export const getSegmentWiseNodes = async (projectId, canvasType) => {
  return await get(
    protectedResources.apiTodoList.endpoint +
    `/myProjectNode/getSegment/${projectId}/${canvasType}`
  )
}

export const updateCanvasRow = async (canvas_id) => {
  return await get(
    protectedResources.apiTodoList.endpoint +
    `/myProjectNode/projects/canvas/updaterow/${canvas_id}`
  )
}

export const insertCanvasRow = async ({ canvas_type,
  canvas_property,
  inserted_by,
  isActive,
  project_id, }) => {

  let data = { canvas_type, canvas_property, inserted_by, isActive, project_id }
  const body =
    data
  return await post(
    protectedResources.apiTodoList.endpoint +
    `/myProjectNode/projects/canvas`, body
  )
}


export const sendEmail = ({ email, due, approval_text }) => {
  let data = { email, due, approval_text }
  post(`${REACT_APP_EMAIL_LOGICAPP}`, data, {
    headers: { "Content-Type": "application/json" },
  })
}

function* fetchNodes() {
  try {
    const response = yield call(getAllNodes)
    yield put(fetchAllNodes(response.studio_component_master))
  } catch (error) {
    console.error("fetchNodes error", error);
    yield put({ type: "ALL_NODE_FETCH_FAILED" })
  }
}

function* fetchNodePropById({ payload: { projectId, compId } }) {
  try {
    const response = yield call(getNodePropByProject, projectId, compId)
    yield put(onFetchNodePropByProjIdSuccess(response))
  } catch (error) {
    yield put({ type: "NODE_PROP_BY_PROJID_FETCH_FAILED" })
  }
}

function* fetchSegmentNodes({ payload: { id, title } }) {
  try {

    const response = yield call(getSegmentWiseNodes, id, title)
    let data = {}
    data.title = title
    data.nodes = response.studio_project_canvas_master
    yield put(setCanvasSegmentNodes(data))
  } catch (error) {
    yield put({ type: "SEGMENT_WISE_NODE_FETCH_FAILED" })
  }
}

function* updateCanvasBySegment({ payload: { canvas_id, canvasRow } }) {
  try {
    yield all([
      call(updateCanvasRow, canvas_id),
      call(insertCanvasRow, canvasRow),
    ])
  } catch (error) {
    yield put({ type: "UPDATE_CANVAS_FAILED" })
  }
}

function* sendApprovalEmail({ payload: { email, due, approval_text } }) {
  try {
    yield call(sendEmail, { email, due, approval_text })
  } catch (error) {
    yield put({ type: "EMAIL_SEND_FAILED" })
  }
}

function* tasksSaga() {
  yield takeEvery(GET_ALL_NODES, fetchNodes),
    yield takeEvery(GET_NODE_PROP_BY_PROJECT, fetchNodePropById),
    yield takeEvery(GET_SEGMENT_NODES_BY_PROJ, fetchSegmentNodes),
    yield takeEvery(UPDATE_CANVAS_ROW_BY_SEGMENT, updateCanvasBySegment)
  yield takeEvery(SEND_APPROVAL_EMAIL, sendApprovalEmail)
}

export default tasksSaga
