import { createStore, applyMiddleware, compose } from "redux"
import { createSlice, configureStore } from "@reduxjs/toolkit"
import createSagaMiddleware from "redux-saga"
import { persistStore, persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage" // defaults to localStorage for web
import rootReducer from "./reducers"
import rootSaga from "./sagas"
import {PublicClientApplication } from "@azure/msal-browser"
const sagaMiddleware = createSagaMiddleware()
import { msalConfig } from "../authConfig";
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const msalInstance = new PublicClientApplication(msalConfig);
/* const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
)
 */
const account = msalInstance.getActiveAccount()
// const userName= localStorage.getItem('clientid')
// console.log(userName,"user name")
const persistConfig = {
  key: "cart",
  storage,
  version: 1
}
// const authPersistConfig = {
//   key: `${account?.username}`,
//   storage: storage,
//   blacklist: ['somethingTemporary'],
//   version: `${account?.username}-1`
// }


const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware => [
    ...getDefaultMiddleware({
      thunk: false,
      immutableCheck: false,
      serializableCheck: false,
    }),

    sagaMiddleware,
  ],
})


export const persistor = persistStore(store)

sagaMiddleware.run(rootSaga)
