export * from "./layout/actions"

// Authentication module
export * from "./auth/register/actions"
export * from "./auth/login/actions"
export * from "./auth/forgetpwd/actions"
export * from "./auth/profile/actions"

// Data Market
export * from "./datamarket/actionTypes"

// DEV STUDIO
export * from "./development-studio/actionTypes"
//Ecommerce
export * from "./e-commerce/actions"

//Calendar
export * from "./calendar/actions"

//chat
export * from "./chat/actions"

//crypto
export * from "./crypto/actions"

//invoices
export * from "./invoices/actions"

// projects
export * from "./projects/actions"

// tasks
export * from "./tasks/actions"

// contacts
export * from "./contacts/actions"

// contacts
export * from "./mails/actions"

//dashboard
export * from "./dashboard/actions"

//dashboard-saas
export * from "./dashboard-saas/actions"

//studio ide actions
export {
  addNode,
  connectNodes,
  selectNode,
  resetProjectNodes,
} from "./studio-ide/reducer"
