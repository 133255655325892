import React, { useState, useEffect, useCallback } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Row,
  Col,
  Collapse,
} from "reactstrap"
import SimpleBar from "simplebar-react"
import classnames from "classnames"
import { protectedResources } from "../../../authConfig"

import { withTranslation } from "react-i18next"
import { useMsal } from "@azure/msal-react"
import PerfectScrollbar from "react-perfect-scrollbar"
import Moment from "react-moment"
import moment from "moment"
import { socket, dmSocket } from "../../../constants/socket"
import 'moment-timezone'
import NOTIFICATION_ICON from "assets/images/NotificationBell.svg"
import NOTIFICATION_ANIM from "assets/images/gif/Notification_Anim.gif"

const { REACT_APP_UTCDIFFERENCE } = process.env
const NotificationDropdown = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)
  // const [products, setProducts] = useState([])
  const { instance, accounts } = useMsal()
  const [date, setDate] = useState(
    {
      Today: [],
      Yesterday: [],
      Friday: [],
      Thursday: [],
      Wednesday: [],
      Tuesday: [],
      Monday: [],
      Lastweek: [],
    },
  )
  const [totalNotification, setTotalNotification] = useState(0)
  const [accordion, setAccordion] = useState(null)
  const [liveNotify, setLiveNotify] = useState([])
  const [emptyNotify, setEmptyNotify] = useState()
  if (accounts.length > 0) {
    for (var i = 0; i < accounts.length; i++) {
      var name = accounts[i]["name"]
      var emailId = accounts[i]["username"]
    }
  }
  useEffect(() => {
    if (!socket) return;
    socket.on("project", (user) => {
      user?.forEach((el) => el == emailId ? setLiveNotify((prev) => [...prev, el]) : null)
    })
    dmSocket.on("dataset", (user) => {
      user?.forEach((el) => el == emailId ? setLiveNotify((prev) => [...prev, el]) : null)
    })
    return () => {
      setLiveNotify([])
      socket.off('project', () => setLiveNotify([]));
      dmSocket.off('dataset', () => setLiveNotify([]))
      socket.off('connect')
      dmSocket.off('connect')
    }
  }, [socket, dmSocket])

  useEffect(async () => {
    await notificationCount()
  }, [])

  const notificationCount = async () => {
    // const headers = new Headers()
    const options = {
      method: "GET",
      // headers: headers,
    }
    return fetch(
      protectedResources.apiTodoList.endpoint +
      `/notification/notificationDetail/${emailId}`,
      options
    )
      .then(response => response.json())
      .then(async (data) => {
        let count = 0
        for (const keys in data) {
          let filterData = data[keys].filter((el) => el.read == false)
          count = count + filterData.length
        }
        setTotalNotification(count)
      }).catch(error => console.log(error))
  }
  const notificationRead = async (project) => {
    // const headers = new Headers()
    // headers.append("Content-Type","application/json")
    const options = {
      method: "PUT",
      // headers: headers,
      body: JSON.stringify({ "project": project })
    }
    return fetch(
      protectedResources.apiTodoList.endpoint +
      `/notification/notificationRead`,
      options
    ).then(response => {
      response.text();
      setTotalNotification(0)
      setLiveNotify([])
    }).catch(error => console.log(error))
  }
  const notification = async () => {
    // const headers = new Headers()
    const options = {
      method: "GET",
      // headers: headers,
    }
    return fetch(
      protectedResources.apiTodoList.endpoint +
      `/notification/notificationDetail/${emailId}`,
      options
    )
      .then(response => response.json())
      .then(async (data) => {
        let projectListId = []
        if (Object.keys(data).length === 0) {
          setEmptyNotify("empty")
        }
        for (const keys in data) {
          data[keys].forEach((el) => {
            projectListId.push(el.project_id)
          })
        }
        if (projectListId.length)
          await notificationRead(projectListId)
        setDate(data)
      }).catch(error => console.log(error))
  }
  let openAccordion = key => {
    if (accordion === key) {
      setAccordion("")
    } else {
      setAccordion(key)
    }
  }
  const totalNotificationCount = parseInt(totalNotification) + parseInt(liveNotify.length);
  const calculateTimeDifference = (time) => {

    const notifTime = moment(time).add(moment.duration(parseInt(REACT_APP_UTCDIFFERENCE), 'minutes'))

    const nowDate = moment(new Date());

    let duration = Math.abs(parseInt(nowDate.diff(notifTime, 'hours')));

    return duration
    
  }
  
  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon topnotiIcon"
          tag="button"
          id="page-header-notifications-dropdown"
          onClick={() => !menu ? notification() : null}>
          {/* <i
            className={
              parseInt(totalNotification) + parseInt(liveNotify.length) != 0
                ? "bx bx-bell bx-tada"
                : "bx bx-bell"
            }
          /> */}
          <img src={NOTIFICATION_ICON} height="25" style={{ marginTop: '-5px', }} />
          {(totalNotificationCount != 0) && (<span className="badge bg-danger rounded-pill notification-badge" style={{fontSize: '8px', padding: '6px 4px' }}>
            {totalNotificationCount <= 99 ? totalNotificationCount : `99+`}
          </span>)}
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg p-0 dropdown-menu-end">
          <div className="p-2 scrollbar" style={{ maxHeight: "75vh", minHeight: "6vh", overflowY: 'scroll' }}>
            <h6>Notifications</h6>
            <PerfectScrollbar>
              {emptyNotify != "empty" ? Object.keys(date).map((key, index) =>
                date[key].length !== 0 ? (
                  <div className="accordion accordion-item accordion-flush" key={index}>
                    <div className="accordion-header">
                      <button
                        className={classnames(
                          key == accordion
                            ? classnames(
                              "accordion-button accordion-flush ",
                              "fw-medium",
                              "accordion-bgcolor"
                            )
                            : classnames(
                              "accordion-button accordion-flush collapsed",
                              "fw-medium"
                            )
                        )}
                        type="button"
                        // date-bs-toggle="collapse"
                        // data-bs-target={`#dataCollapse${key}`}
                        onClick={() => openAccordion(key)}
                        style={{
                          cursor: "pointer",
                          backgroundColor: "#F4F5F7",
                        }}
                      >
                        {key}
                      </button>
                    </div>
                    <Collapse
                      className="accordion-collapse"
                      isOpen={key === accordion ? true : false}
                    >
                      <Row className="align-items-center"></Row>

                      <SimpleBar>
                        {date[key].map((yes, keys) => (
                          <Link
                            to="#"
                            className="text-reset notification-item"
                            key={keys}
                          >
                            <div className="media">
                              <i
                                className="bx bxs-circle"
                                style={{
                                  width: "5%",
                                  fontSize: "10px",
                                  marginTop: "1.5%",
                                  color: "#FF9665",
                                  marginRight: "2%",
                                }}
                              ></i>

                              <div className="media-body">
                                <div className="font-size-12 text-muted">
                                  <div className="d-flex justify-content-between">
                                    <h6 className="mb-1">
                                      {props.t(`${yes.notification}`)}
                                    </h6>
                                    {key === "Today" ? (
                                      <p className="mb-1">
                                        {/* <Moment
                                          duration={moment(yes.time).add(moment.duration(parseInt(REACT_APP_UTCDIFFERENCE), 'minutes'))}
                                          date={new Date()}
                                          format="hh"

                                        ></Moment> */}
                                        {calculateTimeDifference(yes.time)}
                                        h ago
                                      </p>
                                    ) : (
                                      <></>
                                    )}{" "}
                                  </div>
                                  <p className="mb-0">
                                    <Moment
                                      format="DD MMM YYYY  hh:mm a"
                                      date={moment(yes.time).add(moment.duration(parseInt(REACT_APP_UTCDIFFERENCE), 'minutes'))}
                                    ></Moment>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </Link>
                        ))}

                        {/* <Link to="" className="text-reset notification-item">
                <div className="media">
                  <div className="avatar-xs me-3">
                    <span className="avatar-title bg-success rounded-circle font-size-16">
                      <i className="bx bx-badge-check" />
                    </span>
                  </div>
                  <div className="media-body">
                    <h6 className="mt-0 mb-1">
                      {props.t("Your item is shipped")}
                    </h6>
                    <div className="font-size-12 text-muted">
                      <p className="mb-1">
                        {props.t("If several languages coalesce the grammar")}
                      </p>
                      <p className="mb-0">
                        <i className="mdi mdi-clock-outline" />{" "}
                        {props.t("3 min ago")}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
  
              <Link to="" className="text-reset notification-item">
                <div className="media">
                  <img
                    src={avatar4}
                    className="me-3 rounded-circle avatar-xs"
                    alt="user-pic"
                  />
                  <div className="media-body">
                    <h6 className="mt-0 mb-1">Salena Layfield</h6>
                    <div className="font-size-12 text-muted">
                      <p className="mb-1">
                        {props.t(
                          "As a skeptical Cambridge friend of mine occidental"
                        ) + "."}
                      </p>
                      <p className="mb-0">
                        <i className="mdi mdi-clock-outline" />
                        {props.t("1 hours ago")}{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </Link> */}
                      </SimpleBar>
                    </Collapse>
                  </div>
                ) : (
                  <></>
                )
              ) : <p>No New Notification!</p>}
            </PerfectScrollbar>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

export default withTranslation()(NotificationDropdown)
NotificationDropdown.propTypes = {
  t: PropTypes.any,
}
