import React from "react"
import { Redirect } from "react-router-dom"

// Profile
const UserProfile = React.lazy(() =>
  import("../pages/Authentication/user-profile"),
)
const Faq = React.lazy(() => import("../components/Common/FAQ"))

// Authentication related pages
import Login from "../pages/Authentication/loginPagewithIcon"

const Logout = React.lazy(() => import("../pages/Authentication/Logout"))
const Register = React.lazy(() => import("../pages/Authentication/Register"))
const ForgetPwd = React.lazy(() =>
  import("../pages/Authentication/ForgetPassword")
)

// Public Landing View
const PublicPreview = React.lazy(() => import("../pages/PublicPreview"))
const ExpandedCard = React.lazy(() =>
  import("../pages/PublicPreview/components/ExpandedCard"),
)
// Dashboard
const Dashboard1 = React.lazy(() => import("../pages/Dashboard1/index"))

// MyWorkspace
const MyWorkspace = React.lazy(() => import("pages/MyWorkspace/myworkspace"))
// //Projects
const ProjectsGrid = React.lazy(() => import("../pages/Projects/projects-grid"))
const ProjectsList = React.lazy(() => import("../pages/Projects/projects-list"))
const ProjectsOverview = React.lazy(() =>
  import("../pages/Projects/ProjectOverview/projects-overview"),
)
const ProjectsCreate = React.lazy(() =>
  import("../pages/Projects/createproject/createproject"),
)
const NewPage = React.lazy(() => import("../pages/Projects/newPage"))
const ProjectList = React.lazy(() => import("../pages/Projects/projects-list"))
// Concept Design
const Intro = React.lazy(() => import("../pages/Concept design studio/Intro"))
const BuisnessStatement = React.lazy(() =>
  import("../pages/Concept design studio/BuisnessStatement"),
)
//const ProductPlanning = React.lazy(() => import( "../pages/Concept design studio/ProductPlanning"))
const CreateNew = React.lazy(() =>
  import("../pages/Concept design studio/CreateNew"),
)
const UIlibrary = React.lazy(() =>
  import("../pages/Concept design studio/UI-library"),
)

//Development studio
// const DevelopmentStudio = React.lazy(() => import( "../pages/Development studio/DevelopmentStudio"
const DbSchemaGallary = React.lazy(() =>
  import("../pages/Development studio/DbSchemaGallary"),
)
const StorageTools = React.lazy(() =>
  import("pages/Development studio/StorageTools"),
)
const VizulationTools = React.lazy(() =>
  import("pages/Development studio/VizulationTools"),
)
const ProcessingTools = React.lazy(() =>
  import("pages/Development studio/ProcessingTools"),
)
// const ErdGallery = React.lazy(() => import( "pages/Development studio/ErdGallery"))
const Introduction = React.lazy(() =>
  import("../pages/Development studio/Introduction"),
)
const CartPage = React.lazy(() =>
  import("../pages/Development studio/CartPage"),
)
const Cart = React.lazy(() => import("../pages/Development studio/cart"))
const Genai = React.lazy(() => import("../pages/GenAI/genai"))
// const Ecom = React.lazy(() => import( "pages/Development studio/Ecom"))
// const Retail = React.lazy(() => import( "pages/Development studio/Retail"))
// const Healthcare = React.lazy(() => import( "pages/Development studio/Healthcare"))
// const RetailCustomer = React.lazy(() => import( "pages/Development studio/RetailCustomer"))
// Deployment studio
const DeploymentStudio = React.lazy(() =>
  import("../pages/Deployment studio/DeploymentStudio"),
)

//asset management
const MyAssets = React.lazy(() => import("../pages/Asset Management/MyAssets"))
const UploadAssets = React.lazy(() =>
  import("../pages/Asset Management/uploadAsset"),
)
const DropFile = React.lazy(() => import("../pages/Asset Management/dropZone"))
// const AssetManagement = React.lazy(() => import( "../pages/Asset Management/AssetManagement"))
// Dev Tools
const DevTools = React.lazy(() => import("../pages/Dev Tools/devTools"))
const ArtifactOnBoarding = React.lazy(() =>
  import("../pages/MyWorkspace/ArtifactOnBoarding/index"),
)
const AddArtifactOnBoarding = React.lazy(() =>
  import("../pages/MyWorkspace/ArtifactOnBoarding/create"),
)
const MyArtifactDetails = React.lazy(() =>
  import("../pages/MyWorkspace/ArtifactOnBoarding/artifactDetails"),
)
//Data Management
// const DataManagementPage = React.lazy(() =>
//   import("../pages/Data Management/MainPage")
// )
const Table = React.lazy(() => import("../pages/Data Management/ExpandTable"))
const Url = React.lazy(() => import("../pages/Data Management/Urls"))

// Feature Store
const FeatureStorePage = React.lazy(() =>
  import("../pages/Feature Store/FeatureStorePage"),
)
const Cookbooks = React.lazy(() => import("../pages/Reusable Assest/Cookbook"))
const CookbooksList = React.lazy(() =>
  import("../pages/Reusable Assest/CookbookList"),
)

const MIAccelator = React.lazy(() =>
  import("../pages/Reusable Assest/MlAccelator"),
)
const Datamodel = React.lazy(() => import("../pages/Reusable Assest/Datamodel"))
const uiLibrary = React.lazy(() => import("../pages/Reusable Assest/uiLibrary"))
const UiLibrary = React.lazy(() => import("../pages/Reusable Assest/uiLibrary"))

const Utilites = React.lazy(() =>
  import("../pages/Reusable Assest/UtilitiesScripts"),
)
const Lib = React.lazy(() => import("pages/Reusable Assest/VisualizationLib"))
const StudioIDE = React.lazy(() => import("../pages/StudioIDE"))
const KanvasHome = React.lazy(() => import("../pages/Kanvas"))
//import moat from "pages/Moat/moat"
const moat = React.lazy(() => import("../pages/Moat/moat"))

const index = React.lazy(() => import("pages/Moat/MoatSolutions"))
const SolutionOverview = React.lazy(() => import("pages/Moat/SolutionOverview"))
const SearchData = React.lazy(() => import("pages/Moat/search-data"))
const RelationMap = React.lazy(() => import("pages/Moat/RelationshipMap"))
//admin tools
const AdminTools = React.lazy(() => import("../pages/Admin Tools/adminTools"))
const InfraManagement = React.lazy(() =>
  import("../pages/Admin Tools/InfraManagement"),
)
const AssetManagement = React.lazy(() =>
  import("../pages/Admin Tools/AssetManagement"),
)

//help center
const HelpCenter = React.lazy(() => import("../pages/Help Center/index"))
const ContactUs = React.lazy(() => import("../pages/Help Center/ContactUs"))
const ContactUsTable = React.lazy(() =>
  import("../pages/Help Center/ContactUsTable"),
)
const FeedbackCenter = React.lazy(() =>
  import("../pages/Help Center/FeedbackCenter"),
)
const FeedbackCenterTable = React.lazy(() =>
  import("../pages/Help Center/FeedbackCenterTable"),
)
const KnowledgeCenter = React.lazy(() =>
  import("../pages/Help Center/KnowledgeCenter"),
)
// Data MarketPlace

const DataMarketPlace = React.lazy(() =>
  import("../pages/Data MarketPlace/LandingPage"),
)

const AddNewDataset = React.lazy(() =>
  import("../pages/Data MarketPlace/OverallAddDataset"),
)
// const UploadNewDataset = React.lazy(() =>
//   import("../pages/Data MarketPlace/UploadDataset")
// )

// const UploadERDiagram = React.lazy(() => {
//   import("../pages/Data MarketPlace/UploadERDiagram")
// })
// const UploadWiki = React.lazy(() => {
//   import("../pages/Data MarketPlace/UploadWiki")
// })
// const UploadDDL = React.lazy(() => {
//   import("../pages/Data MarketPlace/UploadDDl")
// })

const DatasetOverview = React.lazy(() =>
  import("../pages/Data MarketPlace/DatasetOverview/DatasetOverview"),
)

const DITools = React.lazy(() => import("../DI Tools/diTools"))
const DIList = React.lazy(() => import("../DI Tools/diList"))
const DIFiles = React.lazy(() => import("../DI Tools/diFiles"))
const diFileViewer = React.lazy(() => import("../DI Tools/diFileViewer"))
const MoatSolution = React.lazy(() =>
  import("pages/Moat/CartProvision/NewSolution/index"),
)

const ProvisionOverview = React.lazy(() => import("pages/Moat/CartProvision"))
const Admin = React.lazy(() => import("pages/Admin Tools/admin"))
const GreatExpectation = React.lazy(() =>
  import("pages/Admin Tools/greatExpectation"),
)

const AzureCreate = React.lazy(() => import("../pages/AzureSynapse/create"))
const AzureList = React.lazy(() => import("../pages/AzureSynapse/index"))
const SystemConfig = React.lazy(() => import("../pages/System Configuration/index"))

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard1 },
  { path: "/FAQs", component: Faq },

  // //profile
  { path: "/profile", component: UserProfile },

  // Asset Management
  // {
  //   path: "/my-workspace/assets/list",
  //   component: MyAssets,
  //   breadcrumb: {
  //     label: "My Assets",
  //     parent: {
  //       label: "My Workspace",
  //       routePath: "/my-workspace",
  //     },
  //   },
  // },
  // {
  //   path: "/my-workspace/assets/upload",
  //   component: UploadAssets,
  //   breadcrumb: {
  //     label: "Upload Assets",
  //     parent: {
  //       label: "My Assets",
  //       routePath: "/my-workspace/assets/list",
  //     },
  //   },
  // },

  //My Solutions
  {
    path: "/my-workspace/solutions/grid",
    component: ProjectsGrid,
    breadcrumb: {
      label: "My Solutions",
      parent: {
        label: "My Workspace",
        routePath: "/my-workspace",
      },
    },
  },
  {
    path: "/my-workspace/solutions/list",
    component: ProjectsList,
    breadcrumb: {
      label: "My Solutions",
      parent: {
        label: "My Workspace",
        routePath: "/my-workspace",
      },
    },
  },
  {
    path: "/my-workspace/solutions/create",
    component: ProjectsCreate,
    breadcrumb: {
      label: "Create Solution",
      parent: {
        label: "My Solutions",
        routePath: "/my-workspace/solutions/grid",
      },
    },
  },
  {
    path: "/my-workspace/solutions/overview/:id",
    component: ProjectsOverview,
    breadcrumb: {
      label: "Solution Overview",
      parent: {
        label: "My Solutions",
        routePath: "/my-workspace/solutions/grid",
      },
    },
  },
  // Kanvas 2.0
  {
    path: "/my-workspace/solutions/studioide2/:id/:type?",
    component: KanvasHome,
    breadcrumb: {
      label: "Kanvas",
      parent: {
        label: "My Solutions",
        routePath: "/my-workspace/solutions/grid",
      },
    },
  },

  // Kanvas
  {
    path: "/my-workspace/solutions/studioide/:id",
    component: StudioIDE,
    breadcrumb: {
      label: "My Kanvas",
      parent: {
        label: "My Solutions",
        routePath: "/my-workspace/solutions/grid",
      },
    },
  },

  {
    path: "/my-workspace/artifact/on-boarding/create/:artifactID?",
    component: AddArtifactOnBoarding,
    breadcrumb: {
      label: "Create Artifact",
      parent: {
        label: "My Artifacts",
        routePath: "/my-workspace/artifact/on-boarding",
      },
    },
  },
  {
    path: "/my-workspace/artifact/artifact-details/:id",
    component: MyArtifactDetails,
    breadcrumb: {
      label: "Artifact Overview",
      parent: {
        label: "My Artifacts",
        routePath: "/my-workspace/artifact/on-boarding",
      },
    },
  },
  {
    path: "/my-workspace/artifact/on-boarding",
    component: ArtifactOnBoarding,
    breadcrumb: {
      label: "My Artifacts",
      parent: {
        label: "My Workspace",
        routePath: "/my-workspace",
      },
    },
  },
  //My Workspace
  {
    path: "/my-workspace",
    component: MyWorkspace,
    breadcrumb: {
      label: "My Workspace",
    },
  },

  {
    path: "/moat/provision/overview/new-solution",
    component: MoatSolution,
    breadcrumb: {
      label: "Create Solution",
      parent: {
        label: "Clone Overview",
        routePath: "/moat/provision/overview",
      },
    },
  },
  { path: "/costdashboard", component: NewPage },
  { path: "/project-list", component: ProjectList },
  // concept design
  { path: "/concept-design/intro", component: Intro },
  { path: "/concept-design/business-statement", component: BuisnessStatement },
  //{ path: "/concept-design/product-planning", component: ProductPlanning },
  { path: "/concept-design/UI-library", component: UIlibrary },
  { path: "/concept-design/create-new", component: CreateNew },
  //data management
  // {
  //   path: "/dataManagent/main_page",
  //   component: DataManagementPage,
  //   breadcrumb: {
  //     label: "External Data Platform",
  //   },
  // },

  { path: "/dataManagent/table", component: Table },
  //Urls which need to updated by admins

  { path: "/dataManagent/urls", component: Url },
  //feature store
  {
    path: "/feature-store/introduction",
    component: FeatureStorePage,
    breadcrumb: {
      label: "Feature Store",
    },
  },

  //Dev Tools
  {
    path: "/dev-tools/cookbooks",
    component: Cookbooks,
    breadcrumb: {
      label: "Cookbooks",
      parent: {
        label: "Dev Tools",
        routePath: "/dev-tools",
      },
    },
  },

  {
    path: "/dev-tools/cookbooksList",
    component: CookbooksList,
    breadcrumb: {
      label: "CookbooksList",
      parent: {
        label: "Dev Tools",
        routePath: "/dev-tools",
      },
    },
  },

  {
    path: "/dev-tools/uiLibrary",
    component: UiLibrary,
    breadcrumb: {
      label: "UiLibrary",
      parent: {
        label: "Dev Tools",
        routePath: "/dev-tools",
      },
    },
  },
  {
    path: "/dev-tools/ge",
    component: GreatExpectation,
    breadcrumb: {
      label: "UiLibrary",
      parent: {
        label: "Dev Tools",
        routePath: "/dev-tools",
      },
    },
  },
  // {
  //   path: "/dev-tools/on-boarding/create/:artifactID?",
  //   component: AddArtifactOnBoarding,
  //   breadcrumb: {
  //     label: "Create New Artifact",
  //     parent: {
  //       label: "Artifact OnBoarding",
  //       routePath: "/dev-tools/on-boarding",
  //     },
  //   },
  // },
  // {
  //   path: "/dev-tools/on-boarding",
  //   component: ArtifactOnBoarding,
  //   breadcrumb: {
  //     label: "Artifact OnBoarding",
  //     parent: {
  //       label: "Dev Tools",
  //       routePath: "/dev-tools",
  //     },
  //   },
  // },
  {
    path: "/dev-tools",
    component: DevTools,
    breadcrumb: {
      label: "Dev Tools",
    },
  },

  //development studio
  { path: "/development-studio/introduction", component: Introduction },
  { path: "/development-studio/schema-gallary", component: DbSchemaGallary },
  { path: "/development-studio/processingtools", component: ProcessingTools },
  { path: "/development-studio/vizulationtools", component: VizulationTools },
  { path: "/development-studio/storagetools", component: StorageTools },
  { path: "/development-studio/cart", component: CartPage },
  { path: "/development-studio/cartapproval", component: Cart },
  //deployment studio;
  { path: "/deployment-studio", component: DeploymentStudio },

  //Reusable Assest
  {
    path: "/reusable-asset/cookbook",
    component: Cookbooks,
    breadcrumb: {
      label: "Cookbook",
      parent: {
        label: "Dev Tools",
        routePath: "/dev-tools",
      },
    },
  },
  { path: "/reusable-asset/MIAccelator", component: MIAccelator },
  { path: "/reusable-asset/visual", component: Lib },
  { path: "/reusable-asset/utilities", component: Utilites },
  { path: "/reusable-asset/uilib", component: uiLibrary },
  { path: "/reusable-asset/datamodel", component: Datamodel },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name

  // Moat Design
  {
    path: "/moat/home",
    component: moat,
    breadcrumb: {
      label: "Home",
    },
  },
  {
    path: "/moat/solution/overview/:id",
    component: SolutionOverview,
    breadcrumb: {
      label: "Solution Overview",
      parent: {
        label: "Discover Atom",
        routePath: "/moat/solution/1",
      },
    },
  },
  {
    path: "/moat/solution/mapping/:id",
    component: RelationMap,
    breadcrumb: {
      label: "Solution Mapping",
    },
  },
  {
    path: "/moat/solution/:id",
    component: index,
    breadcrumb: {
      label: "Discover Atom",
    },
  },
  { path: "/moat/solution/overview", component: SolutionOverview },
  {
    path: "/moat/provision/overview",
    component: ProvisionOverview,
    breadcrumb: {
      label: "Clone Overview",
      parent: {
        label: "Discover Atom",
        routePath: "/moat/solution/1",
      },
    },
  },

  {
    path: "/moat/searchData/:id",
    component: SearchData,
    breadcrumb: {
      label: "Search Result",
      parent: {
        label: "Discover Atom",
        routePath: "/moat/home",
      },
    },
  },
  {
    path: "/admin/:slug?/:second?",
    component: Admin,
    breadcrumb: {
      label: "Admin Tools",
    },
  },
  {
    path: "/system-configuration/:slug?/:second?",
    component: SystemConfig,
    breadcrumb: {
      label: "System Configuration",
    },
  },
  // Admin Tools
  // {
  //   path: "/admin/tools",
  //   component: AdminTools
  // },
  // {
  //   path: "/admin-tools/infra-management",
  //   component: InfraManagement,
  //   breadcrumb: {
  //     label: "Infra management",
  //     parent: {
  //       label: "Admin Tools",
  //       routePath: "/admin-tools"
  //     }
  //   }
  // },
  // {
  //   path: "/admin-tools/asset-management",
  //   component: AssetManagement,
  //   breadcrumb: {
  //     label: "Assets management",
  //     parent: {
  //       label: "Admin Tools",
  //       routePath: "/admin-tools"
  //     }
  //   }
  // },

  // Help Center
  {
    path: "/help/feedback",
    component: FeedbackCenter,
    breadcrumb: {
      label: "Feedback",
      parent: {
        label: "Help",
        routePath: "/help",
      },
    },
  },
  {
    path: "/help/feedback-table",
    component: FeedbackCenterTable,
    breadcrumb: {
      label: "Feedback",
      parent: {
        label: "Help",
        routePath: "/help",
      },
    },
  },
  {
    path: "/help/knowledge-center",
    component: KnowledgeCenter,
    breadcrumb: {
      label: "Knowledge Center",
      parent: {
        label: "Help",
        routePath: "/help",
      },
    },
  },
  {
    path: "/help/contact-us",
    component: ContactUs,
    breadcrumb: {
      label: "Contact Us",
      parent: {
        label: "Help",
        routePath: "/help",
      },
    },
  },
  {
    path: "/help/contact-us-table",
    component: ContactUsTable,
    breadcrumb: {
      label: "Contact Us",
      parent: {
        label: "Help",
        routePath: "/help",
      },
    },
  },
  {
    path: "/help",
    component: HelpCenter,
    breadcrumb: {
      label: "Help Center",
    },
  },

  //  Document Intelligence Tools

  {
    path: "/di-tools/di-data/sancus/:id",
    component: diFileViewer,
    breadcrumb: {
      label: "File Viewer",
      parent: {
        label: "Sancus",
        routePath: "/di-tools/di-data/sancus",
      },
    },
  },

  {
    path: "/di-tools/di-data/lease/:id",
    component: diFileViewer,
    breadcrumb: {
      label: "File Viewer",
      parent: {
        label: "Lease",
        routePath: "/di-tools/di-data/lease",
      },
    },
  },
  {
    path: "/di-tools/di-data/contract/:id",
    component: diFileViewer,
    breadcrumb: {
      label: "File Viewer",
      parent: {
        label: "Contract",
        routePath: "/di-tools/di-data/contract",
      },
    },
  },
  {
    path: "/di-tools/di-data/lease",
    component: DIFiles,
    breadcrumb: {
      label: "Lease",
      parent: {
        label: "Drive",
        routePath: "/di-tools/di-list",
      },
    },
  },
  {
    path: "/di-tools/di-data/contract",
    component: DIFiles,
    breadcrumb: {
      label: "Contract",
      parent: {
        label: "Drive",
        routePath: "/di-tools/di-list",
      },
    },
  },
  {
    path: "/di-tools/di-data/sancus",
    component: DIFiles,
    breadcrumb: {
      label: "Sancus",
      parent: {
        label: "Drive",
        routePath: "/di-tools/di-list",
      },
    },
  },

  {
    path: "/di-tools/di-list",
    component: DIList,
    breadcrumb: {
      label: "Drive",
      parent: {
        label: "Automation Intelligence Studio",
        routePath: "/di-tools",
      },
    },
  },

  {
    path: "/di-tools",
    component: DITools,
    breadcrumb: {
      label: "Automation Intelligence Studio",
    },
  },

  // data Market place

  {
    path: "/data-market-place/add-new-dataset",
    component: AddNewDataset,
    breadcrumb: {
      label: "Data MarketPlace",
    },
  },
  // {
  //   path: "/data-market-place/upload-new-dataset",
  //   component: UploadNewDataset,
  //   breadcrumb: {
  //     label: "Data MarketPlace",
  //   },
  // },
  // {
  //   path: "/data-market-place/upload-er-diagram",
  //   component: UploadERDiagram,
  //   breadcrumb: {
  //     label: "Data MarketPlace",
  //   },
  // },
  // {
  //   path: "/data-market-place/upload-wiki",
  //   component: UploadWiki,
  //   breadcrumb: {
  //     label: "Data MarketPlace",
  //   },
  // },
  // {
  //   path: "/data-market-place/upload-ddl",
  //   component: UploadDDL,
  //   breadcrumb: {
  //     label: "Data MarketPlace",
  //   },
  // },
  {
    path: "/data-market-place/dataset-overview/:id",
    component: DatasetOverview,
    breadcrumb: {
      label: "Dataset Overview",
    },
  },
  {
    path: "/data-market-place",
    component: DataMarketPlace,
    breadcrumb: {
      label: "Data MarketPlace",
    },
  },

  {
    path: "/enterprise-data-model/create",
    component: AzureCreate,
    breadcrumb: {
      label: "Create New Data Model",
      parent: {
        label: "Enterprise Data Model",
        routePath: "/enterprise-data-model",
      },
    },
  },
  {
    path: "/enterprise-data-model/:viewtype?",
    component: AzureList,
    breadcrumb: {
      label: "Enterprise Data Model",
    },
  },
  {
    path: "/genai",
    component: Genai,
    breadcrumb: {
      label: "Gen AI",
    },
  },
  { path: "/", exact: true, component: () => <Redirect to="/moat/home" /> },
]

const publicRoutes = [
  { path: "/", component: Login },
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  // { path: "/forgot-password", component: ForgetPwd },
  // { path: "/register", component: Register },
  // { path: "/landing", component: PublicPreview },
  // { path: "/card", component: ExpandedCard },
]

export { publicRoutes, authProtectedRoutes }
