import { createSlice } from "@reduxjs/toolkit"
import _ from "lodash"

const initialState = {
  selectedNode: {},
  selectedNodeProps: {},
  addedNode: [],
  metadata: [],
  allNodes: [],
}

const studioIdeSlice = createSlice({
  name: "studioide",
  initialState,
  reducers: {
    selectNode(state, action) {
      // ✅ This "mutating" code is okay inside of createSlice!
      state.selectedNode = action.payload
      state.selectedNodeProps = state.metadata.find(
        ({ component_name }) => component_name === action.payload.data.label
      )
    },
    addNode(state, action) {
      if (state[action.payload.title]) {
        let newNode = action.payload.newNode
        if (!_.find(state[action.payload.title], { id: newNode.id })) {
          state[action.payload.title].push(action.payload.newNode)
          state[`${action.payload.title}_provision`] = true
        }
      } else {
        state[action.payload.title] = []
        state[action.payload.title].push(action.payload.newNode)
        state[`${action.payload.title}_provision`] = true
      }
    },
    connectNodes(state, action) {
      let { title, id } = action.payload
      state[`${title}_canvas_provision`] = false
      let filtered = Object.keys(state).filter(e =>
        e.endsWith(`${title}_canvas`)
      )
      filtered.map(e => {
        let nodes = state[e]
        _.remove(nodes, e => e.id.startsWith("edge_"))
        let newNodes = []
        if (nodes.length >= 1) {
          nodes = _.orderBy(nodes, obj => parseInt(obj.node_order), ["asc"])
          let edgeCounter = 1
          for (let i = 0; i < nodes.length - 1; i++) {
            newNodes.push(nodes[i])
            newNodes.push({
              id: `edge_${edgeCounter++}`,
              source: nodes[i].id,
              target: nodes[i + 1].id,
              // animated: true,
              style: { stroke:"#625E5D"},
            })
          }
          newNodes.push(nodes[nodes.length - 1])
        }
        state[e] = newNodes
      })
    },

    nodesLoading(state, action) {
      return {
        ...state,
        status: "loading",
      }
    },

    fetchAllNodes(state, action) {
      state.metadata = action.payload
      state.allNodes = _.chain(action.payload)
        .groupBy("solution_phase")
        .map(function (v, i) {
          return {
            name: i,
            nodes: _.chain(v)
              .groupBy("resource_type")
              .map((val, ind) => {
                return {
                  title: ind,
                  order: val[0].component_order,
                  children: val,
                }
              })
              .value(),
          }
        })
        .value()
    },

    setCanvasSegmentNodes(state, action) {
      state[`${action.payload.title}_canvas`] =
        action.payload.nodes[0].canvas_property

      state[`${action.payload.title}_canvas_id`] =
        action.payload.nodes[0].project_canvas_master_id
    },

    getNodesFail() {},

    onFetchNodePropByProjIdSuccess(state, action) {
      state.projToolProp = action.payload.studio_project_details[0]
      if (!_.isEmpty(action.payload.studio_project_details[0])) {
        window.open(
          action.payload.studio_project_details[0].component_url.trim(),
          "_blank",
          "noopener,noreferrer"
        )
      }
    },

    resetProjectNodes(state, action) {
      let filteredCanvasNodes = Object.keys(state).filter(e =>
        e.endsWith(`_canvas`)
      )
      filteredCanvasNodes.map(e => (state[e] = []))

      let filteredCanvasNodeIds = Object.keys(state).filter(e =>
        e.endsWith(`_canvas_id`)
      )
      filteredCanvasNodeIds.map(e => (state[e] = null))

      state.selectedNode = {}
      state.selectedNodeProps = {}
      state.addedNode = []
    },
  },
})

export const {
  selectNode,
  addNode,
  connectNodes,
  fetchAllNodes,
  onFetchNodePropByProjIdSuccess,
  setCanvasSegmentNodes,
  resetProjectNodes,
} = studioIdeSlice.actions

export default studioIdeSlice.reducer
