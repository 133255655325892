import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import login_bg_img from "../../assets/images/atom/login_bg_img.png"
import { CardBody, Card, Container, CardFooter, CardTitle } from "reactstrap"
import { redirectUrl } from "../../constants/redirectUrl"
import { useHistory, withRouter } from "react-router-dom"
import { AvForm, AvField } from "availity-reactstrap-validation"
import microsoft from "assets/images/brands/Microsoft.png"
import { useMsal, useIsAuthenticated } from "@azure/msal-react"
import { InteractionStatus } from "@azure/msal-browser"

const Login = props => {
  const [url, setUrl] = useState("")
  console.log("url" , url)
  const { instance, inProgress } = useMsal()
  const isAuthenticated = useIsAuthenticated()
  const redirectAfterLoginPage = "/moat/home"
  let history = useHistory()

  useEffect(() => {
    // if (isAuthenticated) {
    //   instance.loginRedirect({
    //     redirectStartPage: redirectAfterLoginPage,
    //   })
    // }
  })

  const handleLogin = loginType => {
    if (loginType === "redirect") {
     if (url.length > 0) {
        instance.loginRedirect({
          redirectStartPage: url,
        })
      } else {
        instance.loginRedirect({
          redirectStartPage: "/moat/home",
        })
      } 
      if (inProgress === InteractionStatus.None && !isAuthenticated) {
        instance.loginRedirect({
          redirectStartPage: redirectAfterLoginPage,
        })
      } else {
        history.push(redirectAfterLoginPage)
      }
    }
  }

  return (
    <div style={{ backgroundColor: "#FFFFFF", marginLeft: "-12px" }}>
      <Container fluid>
        <Card
          className="login_form"
          style={{
            float: "left",
            position: "absolute",
            left: "55%",
            top: "15%",
            zIndex: "1000",
            backgroundColor: "#1a4a74",
            padding: "5px",
            color: "#FFFFFF",
            fontWeight: "bold",
            width: "30%",
            height: "65%",
            border: "1px solid white",
          }}
        >
          <CardBody>
            <CardTitle
              style={{
                fontSize: "3vw",
                fontWeight: "bold",
                color: "#FE5000",
                textAlign: "center",
              }}
            >
              ATOM.AI
            </CardTitle>
            <AvForm>
              <AvField
                style={{ fontSize: ".8rem" }}
                name="startIn"
                label="Start In"
                type="select"
                value={url}
                onChange={e => setUrl(e.target.value)}
                // className="optionDropDownIcon"
              >
                {/* <option value="" disabled selected >
                  Select where to start
                </option> */}
                {redirectUrl.map(e => (
                  <option key={e.name} value={e.url}>
                    {e.name}
                  </option>
                ))}
              </AvField>
            </AvForm>
            <br />
            <label style={{ fontSize: ".8rem" }}>
              Sign in using Microsoft credentials
            </label>
            <br />
            <div>
              <button
                style={{ fontSize: "0.9rem" }}
                className="btn btn-block loginbutton form-control"
                type="submit"
                onClick={() => handleLogin("redirect")}
              >
                Sign In
                <img src={microsoft} className="ps-3" />
              </button>
            </div>
          </CardBody>
          <CardFooter style={{ fontSize: "0.6rem", textAlign: "left" }}>
            © Copyright Tredence 2022
          </CardFooter>
        </Card>
        <img
          src={login_bg_img}
          alt=""
          className="img-fluid"
          style={{
            width: "100vw",
            height: "100vh",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        />
      </Container>
    </div>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
