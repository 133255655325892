import PropTypes from "prop-types"
import React, { Suspense } from "react"

import { Switch, Route, BrowserRouter as Router } from "react-router-dom"
import { connect } from "react-redux"

// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes"


// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

// Import scss
import "./assets/scss/theme.scss"
import 'ag-grid-community/styles/ag-grid.css';
// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper"

import fakeBackend from "./helpers/AuthType/fakeBackend"
import { unregister } from "./interceptor"
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react"
import Loader from "components/Common/Loader"
import LoginPagewithIcon from "pages/Authentication/loginPagewithIcon"
import BreadCrumbs from "components/VerticalLayout/BreadCrumbs"

// Activating fake backend
fakeBackend()

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_APIKEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// }

// init firebase backend
// initFirebaseBackend(firebaseConfig)

const App = props => {
  function getLayout() {
    let layoutCls = VerticalLayout
    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout
        break
      default:
        layoutCls = VerticalLayout
        break
    }
    return layoutCls
  }

  const Layout = getLayout()

  return (
    <>
      {authProtectedRoutes && (
        <>
          <AuthenticatedTemplate>
            <VerticalLayout>

              <Suspense fallback={<Loader />}>
                <Switch>
                  {authProtectedRoutes.map((route, i) => {
                    return typeof route.component !== -1 ? (
                      <Route
                        key={i}
                        path={route.path}
                        exact={route.exact}
                       
                        render={props =>{ return (<> <BreadCrumbs breadcrumb={route.breadcrumb} />
                        <route.component {...props} /></> )}}
                      />
                    ) : null
                  })}
                </Switch>
              </Suspense>
            </VerticalLayout>
          </AuthenticatedTemplate>

          <UnauthenticatedTemplate>
            <LoginPagewithIcon />
          </UnauthenticatedTemplate>
        </>
      )}
    </>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)
