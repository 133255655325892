export const getBrowserInfo =  () => {
    const userAgent = navigator.userAgent;
    const browser = {
      name: '',
      version: ''
    };
  
    if (userAgent.indexOf('Opera') > -1) {
      browser.name = 'Opera';
      browser.version = userAgent.substring(userAgent.indexOf('Opera') + 6, userAgent.indexOf('Version'));
    } else if (userAgent.indexOf('Chrome') > -1) {
      browser.name = 'Chrome';
      browser.version = userAgent.substring(userAgent.indexOf('Chrome') + 7, userAgent.indexOf('Safari'));
    } else if (userAgent.indexOf('Safari') > -1) {
      browser.name = 'Safari';
      browser.version = userAgent.substring(userAgent.indexOf('Safari') + 7, userAgent.indexOf('Version'));
    } else if (userAgent.indexOf('Firefox') > -1) {
      browser.name = 'Firefox';
      browser.version = userAgent.substring(userAgent.indexOf('Firefox') + 8, userAgent.indexOf(')'));
    } else if (userAgent.indexOf('MSIE') > -1) {
      browser.name = 'Internet Explorer';
      browser.version = userAgent.substring(userAgent.indexOf('MSIE') + 5, userAgent.indexOf(';'));
    } else {
      browser.name = 'Other';
      browser.version = '';
    }
  
    return JSON.stringify(browser);
  }