/** ADD TO CART*/
export const ADD_TO_CART = "ADD_TO_CART"
export const ADD_ALGO_TO_CART = "ADD_ALGO_TO_CART"
export const ADD_DATA_TO_CART = "ADD_DATA_TO_CART"
export const ADD_SOLN_TO_CART = "ADD_SOLN_TO_CART"
export const ADD_UX_TO_CART = "ADD_UX_TO_CART"
export const DELETE_DATA_TO_CART = "DELETE_DATA_TO_CART"
export const DELETE_ALGO_TO_CART = "DELETE_ALGO_TO_CART"
export const DELETE_SOLN_TO_CART = "DELETE_SOLN_TO_CART"
export const DELETE_UX_TO_CART = "DELETE_UX_TO_CART"
export const RESET_TO_CART="RESET_TO_CART"
export default {
  ADD_TO_CART,
  ADD_ALGO_TO_CART,
  ADD_DATA_TO_CART,
  ADD_SOLN_TO_CART,
  ADD_UX_TO_CART,
  DELETE_DATA_TO_CART,
  DELETE_ALGO_TO_CART,
  DELETE_SOLN_TO_CART,
  DELETE_UX_TO_CART,
  RESET_TO_CART
}
