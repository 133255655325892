import { msalInstance } from "./index"
import { protectedResources } from "./authConfig"
import {
  LogLevel,
  AuthenticationScheme,
  InteractionRequiredAuthError,
} from "@azure/msal-browser"

export const getTokenDM = async (method, query) => {
  const account = msalInstance.getActiveAccount()

  if (!account) {
    throw Error(
      "No active account! Verify a user has been signed in and setActiveAccount has been called."
    )
  }

  const tokenRequest = {
    scopes: [...protectedResources.apiTodoList.scopes],
    authenticationScheme: AuthenticationScheme.POP,
    resourceRequestMethod: method,
    resourceRequestUri: query
      ? protectedResources.apiTodoList.endpoint2 + query
      : protectedResources.apiTodoList.endpoint2,
  }
  try {
    const response = await msalInstance.acquireTokenSilent({
      account: account,
      ...tokenRequest,
    })
    return response.accessToken
  } catch (error) {
    if (
      InteractionRequiredAuthError.isInteractionRequiredError(error.errorCode)
    ) {
      const response = await this.authService.acquireTokenPopup(tokenRequest)
      return response.accessToken
    }
  }
}
