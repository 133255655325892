import { protectedResources } from "authConfig"
export const sendEvent = async (event, email, properties) => {
    const sendEventPostHog = {
      event_name: event,
      distinctId: email,
      properties: properties,
    }
    const options = {
      method: "POST",
      body: JSON.stringify(sendEventPostHog),
    }
    return fetch(
      protectedResources.apiTodoList.endpoint + "/posthog/sendEvent",
      options,
    )
      .then(response => response.json())
      .catch(error => console.log(error))
  }