import PropTypes from "prop-types"
import React, { useState, useCallback, useRef, useEffect } from "react"
import { connect } from "react-redux"
import ReactDrawer from "react-drawer"
import "react-drawer/lib/react-drawer.css"
import { Link, useLocation, useHistory } from "react-router-dom"
import { Popover, PopoverHeader, PopoverBody, Button } from "reactstrap"

// Import menuDropdown
import LearnDropdown from "../CommonForBoth/TopbarDropdown/LearnDropdown"
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown"
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown"
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"
import RightSidebar from "../CommonForBoth/RightSidebar"
import useDebounce from "../../hooks/useDebouce"
// import logoLightSvg from "../../assets/images/smallAtomLogo.jpg"
//i18n
import { withTranslation } from "react-i18next"
// import HelpBlack from "../../assets/images/sidebar/HelpBlack.svg"
import HelpBlack from "../../assets/images/sidebar/HelpBlack2.svg"
import MAXMIZE_SCREEN from "../../assets/images/MaximizeIcon.svg"
import MINIMIZE_SCREEN from "../../assets/images/MinimizeIcon.svg"
import ChatLeft from "../../assets/images/chat-left.svg"
import Book from "../../assets/images/book.svg"
import Envelope from "../../assets/images/envelope.svg"
// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from "../../store/actions"
import BreadCrumbs from "./BreadCrumbs"
import HeaderMeta from "components/Common/HeaderMeta"

const Header = props => {

  const history = useHistory()
  const location = useLocation()
  const [search, setsearch] = useState(false)

  const [position, setPosition] = useState()
  const [open, setOpen] = useState(false)
  const [fullScreen, setFullScreen] = useState(false)
  const [openHelpPopover, setOpenHelpPopover] = useState(false);
  const wrapperRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setOpenHelpPopover(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const toggleTopDrawer = () => {
    setPosition("right")
    setOpen(!open)
  }

  const onDrawerClose = () => {
    setOpen(false)
  }

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        setFullScreen(true)
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        setFullScreen(true)
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        setFullScreen(true)
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    } else {
      if (document.cancelFullScreen) {
        setFullScreen(false)
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        setFullScreen(false)
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        setFullScreen(false)
        document.webkitCancelFullScreen()
      }
    }
  }

  function tToggle() {
    var body = document.body
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable")
    } else {
      body.classList.toggle("vertical-collpsed")
      body.classList.toggle("sidebar-enable")
    }
  }
  const searchCall = useCallback(
    useDebounce(val => {

      if (val) {
        history.push(`/moat/searchData/${val}`)
      } else {
        history.push(`/moat/home`)
      }

    }, 1000),
    []
  )

  const handleChange = e => {
    searchCall(e.target.value)
  }

  const ColoredLine = ({ color }) => (
    <hr
        style={{
            color: color,
            backgroundColor: color,
            height: 1,
            margin: 0,
            marginBottom: 5,
            marginTop: 5
        }}
    />
  );

  return (
    <React.Fragment>
      <HeaderMeta />
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box d-lg-none d-md-block">
              {/* <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logo} alt="" height="22" />
                </span>
              </Link> */}

              {/* <Link to="/" className="logo">
                <span className="logo-sm">
                  <img src={logoLightSvg} alt="" height={40} width={40} />
                </span>
              </Link> */}
            </div>

            <button
              type="button"
              onClick={() => {
                tToggle()
              }}
              className="btn btn-sm px-3 font-size-16 header-item "
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" />
            </button>
          </div>
          <div
            className="d-flex"
            style={{ width: "auto", alignItems: "center" }}
          >
            {/* <div
              className="d-lg-inline-block ms-1"
              style={{ position: "relative" }}
            >
              <i
                className="fa fa-search"
                style={{
                  zIndex: "1",
                  position: "absolute",
                  top: "33%",
                  left: "3%",
                  alignItems: "center",
                  fontSize: "14px",
                }}
              ></i>
              <input
                className="rounded-pill search-bar-style"
                onChange={handleChange}
                type="search"
                placeholder="  Search for databases, algorithms, solutions..."
                aria-label="Search"
                style={{
                  fontSize: "12px",
                  width: "350px",
                  height: "fit-content",
                  padding: "2%",
                  paddingLeft: "8%",
                  position: "relative",
                }}
              ></input>
            </div>
            <div className="dropdown d-inline-block d-lg-none ms-2">
              <button
                onClick={() => {
                  setsearch(!search)
                }}
                type="button"
                className="btn header-item noti-icon "
                id="page-header-search-dropdown"
              >
                <i className="mdi mdi-magnify" />
              </button>
              <div
                className={
                  search
                    ? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show"
                    : "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                }
                aria-labelledby="page-header-search-dropdown"
              >
                <form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search ..."
                        aria-label="Recipient's username"
                      />
                      <div className="input-group-append">
                        <button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify" />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div> */}

            <div className="dropdown d-none d-lg-inline-block">
              <button
                type="button"
                onClick={() => {
                  toggleFullscreen()
                }}
                className="btn header-item noti-icon topnotiIcon"
                data-toggle="fullscreen"
              >
                {fullScreen ? (
                  // <i style={{ fontSize: '18px' }} className="mdi mdi-arrow-collapse" />
                  <img src={MINIMIZE_SCREEN} height="25" style={{ marginTop: '-5px',  }}/>
                ) : (
                  // <i style={{ fontSize: '18px' }} className="mdi mdi-arrow-expand" />
                  <img src={MAXMIZE_SCREEN} height="25" style={{ marginTop: '-5px',  }}/>
                )}
              </button>
            </div>

             {/* <div className="dropdown d-inline-block dropdown">
              <Link
                to={`/help`}
                style={{ alignItems: "center", color: "#555b6d", fontSize: '1.4em', marginLeft: '12px'  }}
              >
                <i className="bx bxs-help-circle" />
                <img src={HelpBlack} height="20" style={{ marginTop: '-5px',  }}/>
              </Link>
            </div>  */}
            <div style={{marginLeft: "0.8rem"}}>
              <Button
                id="Popover1"
                type="button"
                style={{ backgroundColor: "transparent", border: "none", padding: 0 }}
                onClick={() => {
                  setOpenHelpPopover(!openHelpPopover);
                }}
              >
                <img
                  src={HelpBlack}
                  height="25"
                  style={{ marginTop: "-5px" }}
                />
              </Button>
              <Popover
                flip
                target="Popover1"
                isOpen={openHelpPopover}
                placement="bottom-end"
                onClick={() => {
                  setOpenHelpPopover(false)
                }}
              >
                <PopoverBody style={{ padding: 10 }}>
                  <div
                    style={{ display: "flex", flexDirection: "column" }}
                    ref={wrapperRef}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setOpenHelpPopover(false)
                        history.push("/help/contact-us-table")
                      }}
                    >
                      <div style={{ height: 25 }}>
                        <span className="logo-sm">
                          <img src={Envelope} alt="" height={18} width={18} />
                        </span>
                      </div>
                      <div style={{ marginLeft: 10, height: 25, width: 130 }}>
                        Contact Us
                      </div>
                    </div>
                    <ColoredLine color="#d3d3d3" />

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setOpenHelpPopover(false)
                        history.push("/help/feedback-table")
                      }}
                    >
                      <div style={{ height: 25 }}>
                        <span className="logo-sm">
                          <img src={ChatLeft} alt="" height={18} width={18} />
                        </span>
                      </div>
                      <div style={{ marginLeft: 10, height: 25 }}>Feedback</div>
                    </div>
                    <ColoredLine color="#d3d3d3" />

                    <div
                      style={{
                        display: "flex",
                        height: "40",
                        width: "40",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setOpenHelpPopover(false)
                      }}
                    >
                      <div style={{ height: 25 }}>
                        <span className="logo-sm">
                          <a
                            href={process.env.REACT_APP_KNOWLEDGE_CENTRE_URL}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img src={Book} alt="" height={18} width={18} />
                          </a>
                        </span>
                      </div>
                      <div style={{ marginLeft: 10, height: 25 }}>
                        <a
                          href={process.env.REACT_APP_KNOWLEDGE_CENTRE_URL}
                          target="_blank"
                          rel="noreferrer"
                          style={{ color: "#495057" }}
                        >
                          Knowledge Center
                        </a>
                      </div>
                    </div>
                    <ColoredLine color="#d3d3d3" />
                  </div>
                </PopoverBody>
              </Popover>
            </div>

            <NotificationDropdown />
            {/* <LearnDropdown /> */}
            {/* <div className="d-flex align-items-center topnotiIcon">
              <a
                href={process.env.REACT_APP_WIKI_LINK}
                target="_blank"
                rel="noreferrer"
                // className="design-link-style"
                style={{ alignItems: "center", color: "#555b6d", fontSize: '1.4em' }}
              >
                <i className="bx bx-book-open" />
              </a>
            </div> */}
            <ProfileMenu admin={props.admin} />
            {/* <div
              onClick={toggleTopDrawer} disabled={open}
              className="dropdown d-inline-block"
            >
              <button
                type="button"
                className="btn header-item noti-icon right-bar-toggle "
              >
                <i className="bx bx-cog bx-spin" />
              </button>
            </div> */}
          </div>
        </div>
      </header>
      <ReactDrawer open={open} position={position} onClose={onDrawerClose}>
        <RightSidebar onClose={onDrawerClose} />
      </ReactDrawer>
    </React.Fragment>
  )
}

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
}

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.Layout
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header))
