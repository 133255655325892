import React, { useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
} from "reactstrap"
import SimpleBar from "simplebar-react"

//Import images
import avatar3 from "../../../assets/images/users/avatar-3.jpg"
import avatar4 from "../../../assets/images/users/avatar-4.jpg"

//i18n
import { withTranslation } from "react-i18next"

const LearnDropdown = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >


          <span className="d-none d-xl-inline-block ms-2 me-1">Learn</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem
            tag={Link}
            to="/concept-design/intro" 
          >
            <i className="bx bx-message-square-x me-1" ></i>
            {`Concept Design Studio`}
          </DropdownItem>
          <DropdownItem
            tag={Link}
            to="/development-studio/introduction" 
          >
            <i className="bx bx-brain me-1" ></i>
            {`Development Studio`}
          </DropdownItem>
          <DropdownItem
            tag={Link}
            to="/products-grid" 
          >
            <i className="bx bx-current-location me-1" ></i>
            {`Deployment Studio`}
          </DropdownItem>
          <DropdownItem
            tag="a"
            href="https://thetredenceacademy.talentlms.com/learner/courseinfo/id:450"
            target="_blank"
            rel="noreferrer noopener"
          >
            <i className="bx bx-book" />
            {` LMS - Enablement Toolkit`}
          </DropdownItem>

          <DropdownItem tag={Link} to="/FAQs">
            <i className="bx bx-question-mark " ></i>
            {`  FAQs`}
          </DropdownItem>

          {/* <DropdownItem tag="a" href="auth-lock-screen">
            <i className="bx bx-lock-open font-size-16 align-middle me-1" />
            {props.t("Lock screen")}
          </DropdownItem> */}
          <div className="dropdown-divider" />
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

export default withTranslation()(LearnDropdown)

LearnDropdown.propTypes = {
  t: PropTypes.any,
}
